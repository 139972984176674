<template>
    <form class="w-full overflow-y-auto scroll-bar ">
        <div class="inputbox" id="first">
            <label for="first_name" class="label">First Name<span class="mendet">&#42;</span></label>
            <input placeholder="Enter First Name" v-model="getFirstName" type="text" id="first_name"
                class="input" />
        </div>
        <div class="inputbox" id="middle">
            <label for="middle_name" class="label">Middle Name</label>
            <input placeholder="Enter Middle Name" v-model="getMiddleName" type="text" id="middle_name"
                class="input" />
        </div>
        <div class="inputbox" id="last">
            <label for="last_name" class="label">Last Name<span class="mendet">&#42;</span></label>
            <input placeholder="Enter Last Name" v-model="getLastName" type="text" id="last_name"
                class="input" />
        </div>
        <div class="inputbox " id="date_of_birth">
            <label for="date_of_birth" class="label ">Date of Birth</label>
            <DateTime 
                placeholder="Enter" 
                v-model="getDob"
                type="date" 
                id="date_of_birth" 
                class="datepicker input w-full" 
                value-type="YYYY-MM-DD"
                format="DD-MM-YYYY"
                :append-to-body="false"
                :clearable="true"
                @input="dateChanged($event)"
            />
        </div>
        <div class="inputbox" id="gender">
            <label class="label">Gender</label>
            <div class="flex space-x-4">
                <label class="radio-label">
                    <input v-model="getGender" type="radio" value="Male" class="radio" checked="checked"/>
                    Male
                </label>
                <label class="radio-label">
                    <input v-model="getGender" type="radio" value="Female" class="radio" />
                    Female
                </label>
                <label class="radio-label">
                    <input v-model="getGender" type="radio" value="Not known" class="radio" />
                    Other
                </label>
            </div>
        </div>
    </form>
</template>
<script>
import DateTime from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    name: "Attributes",
    components: {
        DateTime
    },
    props: {
        personData: Object,
    },
    data() {
        return {
        //   personData:{
        //                  first_name: '',
        //                  middle_name:'',
        //                  last_name: '',
        //                  gender:'',
        //                  date_of_birth:'',
        //              }
        };
    },
    methods: {
        dateChanged(event) {
            this.getDob = event;
            console.log(">>>>>>>>>>>",event,this.getDob)

        },
    },
    computed: {
        getFirstName: {
            get() {
                return this.personData ? this.personData.first_name : null;
            },
            set(value) {
                this.personData.first_name = value
            },
        },
        getMiddleName: {
            get() {
                return this.personData ? this.personData.middle_name : null;
            },
            set(value) {
                this.personData.middle_name = value
            },
        },
        getLastName: {
            get() {
                return this.personData ? this.personData.last_name : null;
            },
            set(value) {
                this.personData.last_name = value
            },
        },
        getDob: {
            get() {
                return this.personData ? this.personData.date_of_birth : null;
            },
            set(value) {
                this.personData.date_of_birth = value
            },
        },
        getGender: {
            get() {
                return this.personData ? this.personData.gender : null;
            },
            set(value) {
                this.personData.gender = value
            },
        },
    },
};
</script>    
<style lang="scss" scoped> 
::v-deep{
    .datepicker .mx-input-wrapper input{
        border:none !important;
        padding-left:1px !important;
    }
}  
.label {
    color: var(--blue-shade, #1A2036);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 0.5rem;
    justify-content:start;
}
.inputbox{
    border-radius: 8px;
    background: #F6F6F6;
    width: 100%;
    padding:1rem;
    margin: 1rem 0;
}
.input {
    height: 2.5rem !important;
    border-radius: 10px;
    // border: 0.5px solid var(--grey, #AEB1BA);
    background: var(--white, #FFF);
    width: 100%;
}
.mendet{
    color:#FF0000;
}

.parent {
    input[type="radio"] {
        height: 15px;
        appearance: auto;
    }
}

.dot {
    background-color: #e4e1e1;
}

input:checked~.dot {
    transform: translateX(100%);
    background-color: #900009;
}

.radio-label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 11px;
}

.radio {
    margin-right: 0.25rem;
}
</style>    
<template>
    <form class="w-full overflow-y-auto scroll-bar ">
        <div class="inputbox" id="Company">
            <label for="first_name" class="label">Company Name<span class="mendet">&#42;</span></label>
            <input placeholder="Company Name" v-model="getCompanyName" type="text" id="first_name"
                class="input" />
        </div>
        <div class="inputbox" id="middle">
            <label for="middle_name" class="label">Company Number</label>
            <input placeholder="Enter Company Number" v-model="getCompanyNumber" type="text" id="middle_name"
                class="input" />
        </div>
        <div class="inputbox" id="last">
            <label for="last_name" class="label">Date of Incorporation</label>
            <DateTime 
                placeholder="Enter Incorporation Date" 
                v-model="getIncorporation"
                type="date" 
                id="date_of_birth" 
                class="datepicker input w-full"
                value-type="YYYY-MM-DD" 
                format="DD-MM-YYYY"
                :append-to-body="false"
                :clearable="true"
            />
        </div>
        <div class="inputbox" id="dob">
            <label for="dob" class="label">Date of Dissolution</label>
            <DateTime 
                placeholder="Enter Dissolution Date" 
                v-model="getDissolution"
                type="date" 
                id="date_of_birth" 
                class="datepicker input w-full" 
                value-type="YYYY-MM-DD"
                format="DD-MM-YYYY"
                :append-to-body="false"
                :clearable="true"
            />
        </div>
        <div class="inputbox" id="dob">
            <label for="dob" class="label">Jurisdiction</label>
            <select class="select select-bordered select-md text-gray-400 " v-model="jurisdictionSelection" >
                  <option value="" disabled selected hidden>Jurisdiction</option>
                  <option v-for="(x,i) in allJurisdictionData" :key="i" :value="x.value">{{x.label}}</option>
            </select>
        </div>    
    </form>
</template>
<script>
import { getjurisdictionList } from "../../services.js"
import DateTime from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    name: "Company",
    components: {
        DateTime
    },
    props: {
        companyData: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
          allJurisdictionData:[],           
        };
    },
    methods: {
        async jurisdictionList() {
            try{
            const result = await getjurisdictionList();
            this.allJurisdictionData = result.data.data
            }
            catch(err){
                console.log('err', err)
                this.$toast.error(err.response.data.detail)
            }
        }
    },
    computed: {
        getCompanyName: {
            get() {
                return this.companyData ? this.companyData.company_name : null;
            },
            set(value) {
                this.companyData.company_name = value
            },
        },
        getCompanyNumber: {
            get() {
                return this.companyData ? this.companyData.company_number : null;
            },
            set(value) {
                this.companyData.company_number = value
            },
        },
        getIncorporation: {
            get() {
                return this.companyData ? this.companyData.inc_date : null;
            },
            set(value) {
                this.companyData.inc_date = value
            },
        },
        getDissolution: {
            get() {
                return this.companyData ? this.companyData.date_of_dissolution : null;
            },
            set(value) {
                this.companyData.date_of_dissolution = value
            },
        },
        jurisdictionSelection: {
            get() {
                return this.companyData ? this.companyData.jurisdiction : null;
            },
            set(value) {
                this.companyData.jurisdiction = value
            },
        },
       
    },
    async mounted(){
        await this.jurisdictionList();
    }
};
</script>    
<style lang="scss" scoped>   
::v-deep{
    .datepicker .mx-input-wrapper input{
        border:none !important;
        padding-left:1px !important;
    }
} 
.label {
    color: var(--blue-shade, #1A2036);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 0.5rem;
    justify-content:start;
}
.inputbox{
    border-radius: 8px;
    background: #F6F6F6;
    width: 100%;
    padding:1rem;
    margin: 1rem 0;
}
.input {
    height: 2.5rem !important;
    border-radius: 10px;
    // border: 0.5px solid var(--grey, #AEB1BA);
    background: var(--white, #FFF);
    width: 100%;
}
.mendet{
    color:#FF0000;
}
.parent {
    input[type="radio"] {
        height: 15px;
        appearance: auto;
    }
}

.dot {
    background-color: #e4e1e1;
}

input:checked~.dot {
    transform: translateX(100%);
    background-color: #900009;
}

.radio-label {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 11px;
}

.radio {
    margin-right: 0.25rem;
}
</style>    
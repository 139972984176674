/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import Input from "@/components/input";
import packageCard from "@/components/package-card";
import EntitySidebar from "../../common/sidebar";
import NavbarEntity from "../../common/index";
import NewsCard from "../../common/card-news";
import ButtonGroup from "../../common/button-group";
import Offshore from "../../components/offshore-records";
import AdverseMedia from "../../components/adverse-media";
import Dnb from "../../components/dnb";
import FilterCompnent from "../../common/filter-component";
import Loader from "@/components/loader";
import Button from "@/components/button";
import Empty from "@shared/empty";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import modalContent from "@shared/modal-content";
import modalContent from "../../../../tenant-app/src/components/modal-content";
import FormBuilder from "@shared/components/form-builder";
import { checkPermission } from "@shared/utils/functions";
import FilterIcon from "../../assets/svg/filter.svg";
import NoDataIcon from "../../assets/svg/nodatafound.svg";
import GraphIcon from "../../assets/svg/graph.svg";
import RefreshIcon from "../../assets/svg/refresh.svg";
import RunIntelligenceIcon from "../../assets/svg/run-intelligence.svg";
import News from "../../assets/svg/news.svg";
import CanvasMenu from "../../components/canvas-menu";
import GraphCompenent from "../../components/graph";
import LoaderFull from "../../components/loading-screen";
import { mapGetters } from "vuex";
import CoorporateRecord from "../../components/companyDetails";
import OpenCorporates from "../../components/open-corporates";
import { EventBus } from "@/main.js";

import {
  getRunInteligenceDetails,
  getInteligenceStatus,
  getinternetDataDetails,
  getEntityIdentifiers,
  getriskcategories,
  getGraphData,
  getEntityMenuCount,
  getAnalysisStatus,
  addManualData,
  runIntelligenceCards,
  maunalRunIntelligence,
  getManualData,
  getLicenseIds,
  getAllFeatures,
  getInternetSearchCategory,
  postEachEntitySelectedToolData,
  getSaasToken,
  getCaseDetail,
  // updateCaseName,
  deleteManualData,
} from "../../services";
import {
  getGlobalFilters,
  getInternetQueries,
  getTopicClassifications,
} from "../../pages/search-records/services";
import { isArray } from "lodash";
import Vue from "vue";
import Pagination from "./pagination.vue";
import CourtRecords from "../../components/CourtRecords";
import { getJudyRecordFilter, getJudyRecord } from "../../services";
import plus from "../../assets/svg/plus.svg";
import AccordionCard from "../../common/accordion";
import Attributes from "../../common/attribute";
import company from "../../common/company";
import UserIcon from "../../assets/svg/user.svg";
import CompanyIcon from "../../assets/svg/company.svg";
import offCanvas from "../../common/off-canvas";
import Check from "../../assets/svg/check.svg";
import Question from "../../assets/svg/question.svg";
import Cross from "../../assets/svg/cross-red.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";

import AllTabsWhite from "../../assets/svg/entity-details/all_tabs_white.svg";
import AllTabs from "../../assets/svg/entity-details/all_tabs.svg";
import CircleCheck from "../../assets/svg/entity-details/circle_check.svg";
import CircleQuestion from "../../assets/svg/entity-details/circle_question.svg";
import CircleCross from "../../assets/svg/entity-details/circle_cross.svg";
import CircleMinusIcon from "../../assets/svg/entity-details/circle_minus.svg";

import "@vueform/slider/themes/default.css";
import Tabs from "./tabs.vue";
// import ResultCard from "./ResultCard.vue";
import "@vueform/slider/themes/default.css";
import VueCookies from "vue-cookies";
import SelectQueryAndAnalysis from "../../components/select-query-and-analysis";
import { omit } from "lodash";

export default {
  name: "entity-details",
  components: {
    // ResultCard,
    MinusIcon,
    CompanyIcon,
    Question,
    Cross,
    Check,
    UserIcon,
    AccordionCard,
    "v-attributes": Attributes,
    company,
    Dnb,
    plus,
    Pagination,
    CoorporateRecord,
    AdverseMedia,
    Input,
    NoDataIcon,
    packageCard,
    Loader,
    Button,
    Empty,
    VueMultiselect,
    modalContent,
    FormBuilder,
    NavbarEntity,
    NewsCard,
    FilterIcon,
    GraphIcon,
    RefreshIcon,
    EntitySidebar,
    CanvasMenu,
    ButtonGroup,
    Offshore,
    FilterCompnent,
    News,
    GraphCompenent,
    LoaderFull,
    OpenCorporates,
    CourtRecords,
    RunIntelligenceIcon,
    offCanvas,
    Tabs,
    SelectQueryAndAnalysis,
  },
  title: "Entity Details",
  data() {
    return {
      graphFilterShow: true,
      selectedFilters: {
        node_type: ["person", "company", "country", "address", "risk record"],
        risk_only: false,
      },
      mainColor: "",
      sidebarOpen: false,
      loaderProgress: 0,
      loading: {
        completed: false,
        non_completed: false,
        all: false,
        irrelevant: false,
        potential: false,
      },
      filtersLoading: false,
      initializeTopicClassification: [],
      previousTopicClassifications: [],
      globalFilterData: {
        personsTerm: "",
        namesTerm: "",
        locationsTerm: "",
        organisationsTerm: "",
        statussTerm: "",
        riskssTerm: "",
        personsTotalPages: 0,
        personsCurrentPage: 1,
        organisationsTotalPages: 0,
        organisationsCurrentPage: 1,
        namesTotalPages: 0,
        namesCurrentPage: 1,
        locationsTotalPages: 0,
        locationsCurrentPage: 1,
        categoriesTotalPages: 0,
        categoriesCurrentPage: 1,
        riskssTotalPages: 0,
        riskssCurrentPage: 1,
        statusTotalPages: 0,
        statusCurrentPage: 1,
        confidenceScoreTotalPages: 0,
        confidenceScoreCurrentPage: 1,
        iquiriesTotalPages: 0,
        iquiriesCurrentPage: 1,
        confidenceScore: [
          // { key: "High", count: 0, checked: false },
          // { key: "Low", count: 0, checked: false },
          // { key: "Medium", count: 0, checked: false },
        ],
        status: [
          // { key: "POTENTIAL", checked: false },
          // { key: "UNKNOWN", checked: false },
          // { key: "CONFIRMED", checked: false },
          // { key: "IRRELVANT", checked: false }
        ],
        topic_classification: [],
        matchIdentifiers: [0, 100],
        organisations: [],
        persons: [],
        names: [],
        locations: [],
        categories: [],
        risks: [],
        iQueries: [],
      },
      graphData: null,
      entityRecords: {
        all: [],
        potential: [],
        irrelevant: [],
        completed: [],
        non_completed: [],
      },
      data: [],
      selectedPage: 1,
      // clients: [
      //   {
      //     name: "Corporate Records",
      //     id: 1,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "Company Credit",
      //     id: 2,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "Adverse/PEPs/Sanctions",
      //     id: 3,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "Offshore Records",
      //     id: 4,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "Court Records",
      //     id: 5,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "News",
      //     id: 6,
      //     checkAlert: false,
      //     count: 0,
      //   },
      //   {
      //     name: "Internet Search",
      //     id: 7,
      //     checkAlert: false,
      //     count: 0,
      //   },
      // ],
      clients: [],
      item: [],
      caseData: [],
      loaders: {
        clients: false,
        package: false,
        case: false,
        modal: false,
        caseExists: false,
      },
      selectedClient: null,
      loader: { tools: false, screen: false },
      packages: [],
      package_id: null,
      childComponent: true,
      filter: false,
      graph: true,
      graphLoading: false,
      graphPooling: false,
      isChecked: false,
      isGrouped: false,
      showMenu: false,
      companyDetails: null,
      companyDetailsData: null,
      jurisdictionData: null,
      menuCount: null,
      totalPages: {
        all: 0,
        potential: 0,
        irrelevant: 0,
        completed: 0,
        non_completed: 0,
      },
      touchPagination: {
        all: { page: 1, pageSize: 10, selectedPage: 1 },
        completed: { page: 1, pageSize: 10, selectedPage: 1 },
        non_completed: { page: 1, pageSize: 10, selectedPage: 1 },
        potential: { page: 1, pageSize: 10, selectedPage: 1 },
        irrelevant: { page: 1, pageSize: 10, selectedPage: 1 },
        unknown: { page: 1, pageSize: 10, selectedPage: 1 },
      },
      memorization: {
        opencorporate: [],
        adverse: [],
        news: [],
        offshore: [],
        court: [],
      },
      ownershipData: null,
      totalPage: 1,
      sourceName: null,
      getOptions: [],
      loaderData: [
        "Searching through 1,456,153 Politically Exposed People",
        "Searching through more than 33,000 global news sources",
        "Searching through more than 2,016,524 offshore records",
        "Searching through more than 220,462,742 corporate records",
        "Searching through 1,456,153 Politically Exposed People",
      ],
      debounceTimer: null,
      checkedcount: 0,
      courtFilterData: null,
      published_from_date: "",
      published_to_date: "",
      filling_from_date: "",
      filling_to_date: "",
      courtFilterCount: 0,
      toolFilter: true,
      toolFilterCount: 0,
      dateError: "",
      anylysisloader: 0,
      personData: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
      },
      companyData: {
        company_name: "",
        company_number: "",
        inc_date: "",
        date_of_dissolution: "",
        jurisdiction: "",
      },
      // addbutton: false,
      manualData: {
        employment: [],
        associatedPeople: [],
      },
      runIntelligenceStatus: false,
      intervalID: null,

      tabs: {
        selected: "all",
        list: [
          {
            id: "all",
            label: "All",
            isIcon: true,
            iconSvgName: AllTabsWhite,
            isCount: true,
            counts: 0,
          },
          {
            id: "confirmed",
            label: "Confirmed",
            isIcon: true,
            iconSvgName: CircleCheck,
            isCount: true,
            counts: 0,
          },
          {
            id: "potential",
            label: "Potential",
            isIcon: true,
            iconSvgName: CircleQuestion,
            isCount: true,
            counts: 0,
          },
          {
            id: "irrelevant",
            label: "Irrelevant",
            isIcon: true,
            iconSvgName: CircleCross,
            isCount: true,
            counts: 0,
          },
          {
            id: "unknown",
            label: "Unknown",
            isIcon: true,
            iconSvgName: CircleMinusIcon,
            isCount: true,
            counts: 0,
          },
          // {
          //     id: "creditSource",
          //     label: "Credit & Source",
          // }
        ],
      },
      analysis: [],
      main_entity_loader: false,
      main_entity: [],
      licenseIds: [],
      selectedEntityId: "",
      isEntityDataUpdated: false,
      showMainEntityMoreList: false,
      getAllResultOnlyOnce: true,
      entitySelectLoading: false,
      reRunIntelligence: false,
      runIntelligenceModal: {
        personEntity: [],
        companyEntity: [],
        checkedPersonEntity: [],
        checkedCompanyEntity: [],
      },
      runIntelligenceNextLoading: false,
      runIntelligenceNextDisabled: false,
    };
  },
  computed: {
    ...mapGetters(["getEntitiesData", "getCaseData"]),
    currentDate() {
      const date = new Date();
      return (
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, 0) +
        "-" +
        String(date.getDate()).padStart(2, 0)
      );
    },

    getManualRunintelligenceCount() {
      let employment = this.manualData.employment.filter((i) => i.checked);
      let associatedPeople = this.manualData.associatedPeople.filter(
        (i) => i.checked
      );
      let manualLength = employment.length + associatedPeople.length;
      return manualLength;
    },
    getManualsData() {
      return this.manualData;
    },
    getFillingFromDate: {
      get() {
        return this.filling_from_date;
      },
      set(value) {
        this.filling_from_date = value;
      },
    },
    getFillingToDate: {
      get() {
        return this.filling_to_date;
      },
      set(value) {
        this.filling_to_date = value;
      },
    },
    getCheckedCount() {
      return this.checkedcount;
    },
    totalCustomPages() {
      let key = this.getSelectedTabMatchingKey(this.tabs.selected);
      return Math.ceil(
        this.totalPages[key] / this.touchPagination[key].pageSize
      );
    },
    totalNonCompCustomPages() {
      let key = this.getSelectedTabMatchingKey(this.tabs.selected);
      return Math.ceil(
        this.totalPages[key] / this.touchPagination[key].pageSize
      );
    },
    isloading() {
      return this.loading;
    },
    isShrink() {
      return this.filter || this.graph;
    },
    getProgress() {
      return this.loaderProgress;
    },
    searchedQuery() {
      return this.$route.query;
    },
    filterData() {
      return this.globalFilterData;
    },
    getLoderData() {
      return this.loaderData;
    },
    filteredClients() {
      return this.clients;
    },
    // entityMenuCount(){
    //  return this.menuCount
    // },
    filteredPackages() {
      return this.packages.filter((pkg) =>
        pkg.package_name
          .toLowerCase()
          .includes(this.searchKeywordPackages.toLowerCase())
      );
    },
    selectedPackage() {
      return this.package_id
        ? this.packages.find((pkg) => pkg.id === this.package_id)
        : null;
    },
    ifCheckisReference() {
      return this.packageChecks.filter(
        (el) => el.category.toLowerCase() === "reference"
      ).length;
    },
    filterItem() {
      return this.entityRecords;
    },
    filterCaseData() {
      return this.caseData;
    },
    cardOptions() {
      return this.getOptions;
    },
    handleOpenCorporate() {
      return this.entityRecords;
    },
    handleAllOpenCorporate() {
      return this.entityRecords;
    },
    handleAdverse() {
      return this.entityRecords;
    },
    handleNews() {
      return this.entityRecords;
    },
    handleOffshore() {
      return this.entityRecords;
    },
    handleCourt() {
      return this.entityRecords;
    },
    sourceData() {
      return this.sourceName;
    },
    getUnknownCount() {
      return this.totalPages["non_completed"];
    },
    getConfirmedCount() {
      return this.totalPages["completed"];
    },
    getPotentialCount() {
      return this.totalPages["potential"];
    },
    getIrrelevantCount() {
      return this.totalPages["irrelevant"];
    },
    getAllCount() {
      return this.totalPages["all"];
    },
    getPaginationDisplayValue() {
      const key = this.getSelectedTabMatchingKey(this.tabs.selected);
      return this.touchPagination[key].pageSize;
    },
    getCurrentPage() {
      const key = this.getSelectedTabMatchingKey(this.tabs.selected);
      return this.touchPagination[key].page;
    },
    getLoader() {
      const key = this.getSelectedTabMatchingKey(this.tabs.selected);
      return this.loading[key];
    },
    mainEntitiesData() {
      return this.main_entity;
    },
    entitiesList() {
      return this.$store.getters.getEntitiesList;
    },
    isAnyFeatureAvailable() {
      return this.$store.getters.getIsAnyFeatureAvailable;
    },
    entitiesListLoader() {
      return this.$store.getters.getEntitiesListLoader;
    },
    featureLoading() {
      return this.$store.getters.getFeatureLoader;
    },
    selectedEntityComponentData() {
      return this.$store.getters.getSelectedEntityComponentData;
    },
    internetSearchData() {
      return this.$store.getters.getInternetSearchData;
    },
    analysisData() {
      return this.$store.getters.getAnalysisData;
    },
    showBuildQuery() {
      return (
        this.$store.getters.getShowBuildQuery || this.entitiesList.length === 1
      );
    },
    selectedComponentKey() {
      return this.$store.getters.getSelectedComponentKey;
    },
    saveEntityDataLoader() {
      return this.$store.getters.getSaveEntityDataLoader;
    },
    getShowMainEntityMoreList() {
      return this.$store.getters.showMainEntityMoreList;
    },
    getIsRunIntelligence() {
      return this.$store.getters.getIsRunIntelligenceRun;
    },
    personEntitySelected() {
      return !this.runIntelligenceModal.checkedPersonEntity.length;
    },
    companyEntitySelected() {
      return !this.runIntelligenceModal.checkedCompanyEntity.length;
    },
  },
  async mounted() {
    this.tabs.selected = this.$route?.query?.tab || "all";
    this.setNeoCaseIdIntoSessionStorage();
    const saasToken = VueCookies.get("saas-token");
    if (!saasToken) {
      await this.setSaasToken();
    }
    await this.mainEntity();
    await this.handleOutputMenu();
    this.inteligenceDetails();
    this.getGraph();

    EventBus.$on("getFreshDataAfterSave", () => {
      this.getGlobalFiltersData();
    });
  },

  created() {
    this.companyDetailsData = JSON.parse(
      sessionStorage.getItem(`SelectedData`)
    );
    const rootStyles = getComputedStyle(document.documentElement);
    this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
  },
  async beforeDestroy() {
    clearInterval(this.intervalID);
  },
  async beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalID);
    next();
  },
  methods: {
    companyEntitySelect() {
      this.runIntelligenceNextDisabled =
        !!this.runIntelligenceModal.checkedCompanyEntity.length ||
        !!this.runIntelligenceModal.checkedPersonEntity.length;
    },
    personEntitySelect() {
      this.runIntelligenceNextDisabled =
        !!this.runIntelligenceModal.checkedCompanyEntity.length ||
        !!this.runIntelligenceModal.checkedPersonEntity.length;
    },
    getSvgIcon() {
      if (this.tabs.selected === "all") {
        return AllTabsWhite;
      } else {
        return AllTabs;
      }
    },
    graphUpdate(graphData) {
      if (graphData.selectedFilters) {
        this.selectedFilters = graphData.selectedFilters;
      }
      delete graphData.selectedFilters;
      this.getGraph(graphData);
    },
    pageUpdated() {
      this.updateSelectedPage(this.selectedPage);
    },
    handlePageSizeSelect(evt) {
      let tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.touchPagination[tabType].pageSize = evt.target.value;
      this.handleCustomPage(this.selectedPage, 1, tabType);
    },
    async handleCustomPage(selectedPage, paginationPage, tab, onTabSelected) {
      let type = this.getSelectedTabMatchingKey(tab);
      this.touchPagination[type].page = paginationPage;
      this.loading[type] = true;
      let mainentity_id = this.getSelectedEntityIds();

      if (this.sourceName == "internet") {
        this.getInternetData(paginationPage, type);
      } else {
        let byDefaultAllEntities = [];
        if (!mainentity_id.length) {
          byDefaultAllEntities = this.caseData.map((el) => el._id);
        }
        byDefaultAllEntities = byDefaultAllEntities.filter(
          (item, index) => byDefaultAllEntities.indexOf(item) === index
        );
        const payload = {
          entity_ids: mainentity_id.length
            ? mainentity_id
            : byDefaultAllEntities,
          source_name: this.sourceName,
          current_page: paginationPage,
          limit: this.touchPagination[type].pageSize,
        };
        const appliedFilters = this.getAppliedFilters();
        Object.keys(appliedFilters).forEach((key) => {
          if (appliedFilters[key].length) {
            if (key == "risks") {
              payload.risk_categories = appliedFilters.risks;
            } else if (key == "confidenceScore") {
              payload.confidence_score = appliedFilters.confidenceScore;
            } else if (key == "iQueries") {
              payload.query_ids = appliedFilters.iQueries;
            } else if (key == "topic_classification") {
              payload.categories = appliedFilters.topic_classification;
              this.previousTopicClassifications =
                appliedFilters.topic_classification;
            } else if (key == "names") {
              payload.persons = appliedFilters.names;
            } else if (key == "organisations") {
              payload.organisations = appliedFilters.organisations;
            } else {
              payload[key] = appliedFilters[key];
            }
          } else if (!isArray(appliedFilters[key])) {
            payload[key] = appliedFilters[key];
          }
        });

        this.entityDataSourceBased(payload, type, onTabSelected);
      }
      // await this.handleOutputMenu();
    },
    async getGraph(p) {
      // p = false;
      this.graphLoading = true;
      let case_id = this.$route.query?.case_id;
      let payload = {
        node_type: ["person", "company", "country", "address", "risk"],
        depth: 3,
      };
      let data = [];
      if (p) {
        data = await getGraphData(p, case_id);
      } else {
        data = await getGraphData(payload, case_id);
      }
      this.graphData = data?.data?.data || null;
      this.graphLoading = false;
    },

    navBarItems() {
      if (this.menuCount) {
        const dynamicMenu = [];
        for (const el in this.menuCount) {
          if (el === "opencorporates") {
            dynamicMenu.push({
              name: "Corporate Records",
              id: 1,
              checkAlert: false,
              count: this.menuCount.opencorporates,
            });
          } else if (el === "dnb") {
            dynamicMenu.push({
              name: "Company Credit",
              id: 2,
              checkAlert: false,
              count: this.menuCount.dnb || 0,
            });
          } else if (el === "adverse-media") {
            dynamicMenu.push({
              name: "Adverse/PEPs/Sanctions",
              id: 3,
              checkAlert: false,
              count: this.menuCount[`adverse-media`],
            });
          } else if (el === "offshoreleaks") {
            dynamicMenu.push({
              name: "Offshore Records",
              id: 4,
              checkAlert: false,
              count: this.menuCount.offshoreleaks,
            });
          } else if (el === "court-records") {
            dynamicMenu.push({
              name: "Court Records",
              id: 5,
              checkAlert: false,
              count: this.menuCount[`court-records`],
            });
          } else if (el === "news") {
            dynamicMenu.push({
              name: "News",
              id: 6,
              checkAlert: false,
              count: this.menuCount.news,
            });
          } else if (el === "search-engine") {
            dynamicMenu.push({
              name: "Internet Search",
              id: 7,
              checkAlert: false,
              count: this.menuCount[`search-engine`],
            });
          }
        }

        this.clients = dynamicMenu.sort((a, b) => a.id - b.id);
      }

      // else {
      //   return this.clients;
      // }
    },
    getAppliedFilters() {
      const filters = {};
      this.checkedcount = 0;
      Object.keys(this.globalFilterData).map((key) => {
        if (isArray(this.globalFilterData[key])) {
          if (key == "matchIdentifiers") {
            filters.matching_identifiers_min =
              this.globalFilterData.matchIdentifiers[0];
            filters.matching_identifiers_max =
              this.globalFilterData.matchIdentifiers[1];
            if (
              filters.matching_identifiers_max == 0 &&
              filters.matching_identifiers_min == 0
            ) {
              this.checkedcount -= 1;
            } else {
              this.checkedcount += 1;
            }
          } else if (key == "topic_classification") {
            // let topic_classification = []
            // this.checkedcount+=1;
            filters[key] =
              this.getSelectedTopics(this.globalFilterData[key]) || [];
          } else {
            if (this.globalFilterData[key]) {
              this.globalFilterData[key].map((item) => {
                if (item.checked) {
                  this.checkedcount += 1;
                  let value = item.key.trim();
                  if (key == "status") {
                    value = value == "UNKNOWN" ? "" : value.toUpperCase();
                  }
                  if (!filters[key]) {
                    filters[key] = [];
                  }
                  filters[key].push(value);
                }
              });
            }
          }
        }
      });
      return filters;
    },
    getCourtAppliedFilters() {
      const filters = {};
      this.courtFilterCount = 0;
      if (this.courtFilterData) {
        Object.keys(this.courtFilterData).map((key) => {
          if (isArray(this.courtFilterData[key])) {
            if (this.courtFilterData[key]) {
              this.courtFilterData[key].map((item) => {
                if (item.checked) {
                  this.courtFilterCount++;
                  let value = item.key.trim();
                  if (!filters[key]) {
                    filters[key] = [];
                  }
                  filters[key].push(value);
                }
              });
            }
          }
        });
      }
      return filters;
    },
    getSelectedTopics(nodes) {
      let arr = [];
      for (let child of nodes) {
        if (child.checked) {
          arr.push(child.category);
        }
        if (
          child.children &&
          Array.isArray(child.children) &&
          child.children.length > 0
        )
          arr.push(...this.getSelectedTopics(child.children));
      }
      return arr;
    },
    resetTopicClassification(nodes) {
      for (let child of nodes) {
        child.checked = false;
        if (
          child.children &&
          Array.isArray(child.children) &&
          child.children.length > 0
        )
          this.getSelectedTopics(child.children);
      }
    },

    getSelectedEntityIds() {
      let mainentity_id = this.caseData?.filter((d) => d.checked);
      return (mainentity_id = mainentity_id?.map((d) => d._id));
    },
    async updateSelectedPage(page, sideMenuClick = false) {
      let tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      // this.loading[tabType] = true;
      this.touchPagination[tabType].page = 1;
      this.selectedPage = page;
      this.entityRecords = [];
      this.getAllResultOnlyOnce = true;
      let mainentity_id = this.getSelectedEntityIds();
      let source_name = "complyadvantage";
      this.sourceName = source_name;
      if (page != 7) {
        switch (page) {
          case 1:
            source_name = "opencorporates";
            this.sourceName = source_name;
            this.touchPagination[tabType].pageSize = 10;
            break;
          case 2:
            source_name = "dnb";
            this.sourceName = source_name;
            this.touchPagination[tabType].pageSize = 10;
            break;
          case 3:
            source_name = "complyadvantage";
            this.sourceName = source_name;
            this.touchPagination[tabType].pageSize = 10;
            break;
          case 4:
            source_name = "offshoreleaks";
            this.sourceName = source_name;
            this.touchPagination[tabType].pageSize = 10;
            break;
          case 5:
            source_name = "judyrecords";
            this.sourceName = source_name;
            this.getcategoriesOption();
            this.touchPagination[tabType].pageSize = 10;
            break;
          case 6:
            source_name = "news";
            this.sourceName = source_name;
            this.getcategoriesOption();
            this.touchPagination[tabType].pageSize = 10;
            break;
        }
        let byDefaultAllEntities = [];
        if (!mainentity_id.length) {
          byDefaultAllEntities = this.caseData.map((el) => el._id);
        }
        byDefaultAllEntities = byDefaultAllEntities.filter(
          (item, index) => byDefaultAllEntities.indexOf(item) === index
        );

        const payload = {
          entity_ids: mainentity_id.length
            ? mainentity_id
            : byDefaultAllEntities,
          source_name,
          current_page: 1,
          limit: this.touchPagination[tabType].pageSize,
        };
        const appliedFilters = this.getAppliedFilters();
        Object.keys(appliedFilters).forEach((key) => {
          if (appliedFilters[key].length) {
            if (key == "risks") {
              payload.risk_categories = appliedFilters.risks;
            } else if (key == "confidenceScore") {
              payload.confidence_score = appliedFilters.confidenceScore;
            } else if (key == "locations") {
              payload.locations = appliedFilters.locations;
            } else if (key == "iQueries") {
              payload.query_ids = appliedFilters.iQueries;
            } else if (key == "topic_classification") {
              payload.categories = appliedFilters.topic_classification;
              this.previousTopicClassifications =
                appliedFilters.topic_classification;
            } else if (key == "names") {
              payload.persons = appliedFilters.names;
            } else if (key == "organisations") {
              payload.organisations = appliedFilters.organisations;
            } else {
              payload[key] = appliedFilters[key];
            }
          } else if (!isArray(appliedFilters[key])) {
            payload[key] = appliedFilters[key];
          }
        });
        if (this.sourceName == "judyrecords") {
          let p = {
            case_id: this.$route.query.case_id,
            ...payload,
          };
          this.getCourtFilterData(p, this.$route.query.case_id);
        }
        if (this.getAllResultOnlyOnce) {
          this.getAllResultsCounts(payload, tabType);
        }
        await this.entityDataSourceBased(payload, tabType, sideMenuClick);
      } else {
        this.getAllResultOnlyOnce = true;
        this.sourceName = "internet";
        this.entityRecords = [];
        this.touchPagination[tabType].pageSize = 10;
        await this.getInternetData(1);
        this.getcategoriesOption();
      }
      // this.loading[tabType] = false;
    },
    async entityDataSourceBased(source, s, sideMenuClick = false) {
      const tabType = this.getSelectedTabMatchingKey(s);
      this.loading[tabType] = true;
      this.entitySelectLoading = sideMenuClick ? false : true;
      let payload = {
        case_id: this.$route.query.case_id,
        ...source,
      };
      // let customFilter = false;
      // let customFilterPayload = [];
      // if (this.globalFilterData["status"]) {
      //   this.globalFilterData["status"].map((item) => {
      //     if (item.checked && item.key != "CONFIRMED") {
      //       customFilter = true;
      //       customFilterPayload.push(item.key);
      //       customFilterPayload = [...new Set(customFilterPayload)];
      //     }
      //   });
      // }
      let data;
      if (this.sourceName == "judyrecords") {
        const appliedCourtFilters = this.getCourtAppliedFilters();
        Object.keys(appliedCourtFilters).forEach((key) => {
          if (appliedCourtFilters[key].length) {
            if (key == "case_number") {
              payload.case_number = appliedCourtFilters.case_number;
            } else if (key == "case_type") {
              payload.case_type = appliedCourtFilters.case_type;
            } else if (key == "case_status") {
              payload.case_status = appliedCourtFilters.case_status;
            } else if (key == "jurisdiction") {
              payload.jurisdiction = appliedCourtFilters.jurisdiction;
            } else if (key == "filling_date") {
              payload.filling_date = appliedCourtFilters.filling_date;
            } else if (key == "defendant") {
              payload.defendant = appliedCourtFilters.defendant;
            } else if (key == "plaintiff") {
              payload.plaintiff = appliedCourtFilters.plaintiff;
            } else {
              payload[key] = appliedCourtFilters[key];
            }
          } else if (!isArray(appliedCourtFilters[key])) {
            payload[key] = appliedCourtFilters[key];
          }
        });
        if (this.filling_from_date && this.filling_to_date) {
          (payload.filling_from_date = this.filling_from_date),
            (payload.filling_to_date = this.filling_to_date);
        }
        if (this.tabs.selected === "all") {
          payload.status = [
            "CONFIRMED",
            "COMPLETED",
            "POTENTIAL",
            "IRRELEVANT",
            "UNKNOWN",
            "",
          ];
        } else if (this.tabs.selected === "confirmed") {
          payload.status = ["CONFIRMED"];
        } else if (this.tabs.selected === "potential") {
          payload.status = ["POTENTIAL"];
        } else if (this.tabs.selected === "irrelevant") {
          payload.status = ["IRRELEVANT"];
        } else if (this.tabs.selected === "unknown") {
          payload.status = ["", "UNKNOWN"];
        }
        data = await getJudyRecord(payload);
        this.entityRecords[tabType] = data.data.data.filter(
          (item) =>
            item.source_name === "unicourt" ||
            item.source_name === this.sourceName
        );
        this.totalPages[tabType] = data.data.pagination.total_rows;
        this.loading[tabType] = false;
      } else {
        this.loading[tabType] = true;
        if (this.published_from_date && this.published_to_date) {
          (payload.published_from_date = this.published_from_date),
            (payload.published_to_date = this.published_to_date);
        }
        if (this.tabs.selected === "all") {
          payload.status = [
            "COMPLETED",
            "POTENTIAL",
            "IRRELEVANT",
            "CONFIRMED",
            "UNKNOWN",
            "",
          ];
        } else if (this.tabs.selected === "confirmed") {
          payload.status = ["CONFIRMED"];
        } else if (this.tabs.selected === "potential") {
          payload.status = ["POTENTIAL"];
        } else if (this.tabs.selected === "irrelevant") {
          payload.status = ["IRRELEVANT"];
        } else if (this.tabs.selected === "unknown") {
          payload.status = ["", "UNKNOWN"];
        }
        data = await getEntityIdentifiers(payload);
        this.totalPages[tabType] = data?.data?.pagination?.total_rows;
        this.entityRecords[tabType] = data?.data?.data?.filter(
          (item) => item.source_name === this.sourceName
        );
      }

      // For status filter
      let statusFilter = true;
      if (this.globalFilterData["status"]) {
        this.globalFilterData["status"].map((item) => {
          if (item.checked && item.key == "CONFIRMED") {
            this.entityRecords.non_completed = [];
            this.totalPages[tabType] = 0;
            statusFilter = false;
            payload.status = ["CONFIRMED"];
          } else if (item.checked) {
            this.entityRecords[tabType] = [];
            this.totalPages[tabType] = 0;
            statusFilter = false;
          }
        });
      }

      let countPayload = { ...payload };
      if ("status" in countPayload && statusFilter) {
        delete countPayload.status;
      }
      let mainentity_id = this.getSelectedEntityIds();
      if (this.filterCaseData?.length && !mainentity_id.length) {
        this.filterCaseData.forEach((el) => {
          if (el.run_intelligence) {
            countPayload.entity_ids.push(el?._id);
          }
        });
      }
      countPayload.entity_ids = countPayload.entity_ids.filter(
        (item, index) => countPayload.entity_ids.indexOf(item) === index
      );
      let updatedPayload = countPayload;
      let countdata = await getEntityMenuCount(
        this.$route.query.case_id,
        updatedPayload
      );
      this.menuCount = countdata?.data;
      this.navBarItems();

      const sourceNameList = [
        "opencorporates",
        "complyadvantage",
        "offshoreleaks",
        "judyrecords",
        "news",
      ];
      if (sourceNameList.includes(this.sourceName)) {
        this.clients = this.clients.map((client) => {
          if (client.sourceName === this.sourceName) {
            return {
              ...client,
              checkAlert: this.entityRecords[tabType]?.some(
                (item) => item.risk_categories.length
              ),
            };
          }
          return client;
        });
      }
      this.entitySelectLoading = false;
      this.loading[tabType] = false;
    },
    async mainEntity() {
      let payload = {
        case_id: this.$route.query.case_id,
        main_entity: true,
      };
      let data = await getEntityIdentifiers(payload);
      let temp = [];
      data.data.data.forEach((item) => {
        if (item.title == "New Person") {
          temp.push({
            ...item,
            name: `${item.entity}`,
            categories_type: "officers",
            checked: false,
          });
        } else if (item.title == "New Company") {
          temp.push({
            ...item,
            name: `${item.entity}`,
            categories_type: "ownership",
            checked: false,
          });
        }
      });
      this.caseData = [...temp];
    },
    async handleDeleteEntity(entity_id) {
      try {
        let deleteData = await deleteManualData(entity_id);
        if (deleteData) {
          this.$toast.error("Deleted Successfully");
        }
        await this.mainEntity();
        await this.getGlobalFiltersData();
      } catch (err) {
        console.error(err);
      }
    },
    async inteligenceDetails() {
      const tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.loader.screen = true;
      let payload = {
        case_id: this.$route.query.case_id,
      };
      await getRunInteligenceDetails(payload);
      let flag = 1;
      // let progress = 0;
      while (flag) {
        let data = await getInteligenceStatus(payload);
        if (data.data.data.progress == 0) {
          this.loaderProgress = 8;
        } else {
          this.loaderProgress = data.data.data.progress || 0;
        }
        // if ( progress > 0) {
        // progress = data.data.data.progress;

        await this.mainEntity();

        if (!this.$route.query.skip) {
          let countPayload = {
            entity_ids: [],
            persons: [],
            organisations: [],
            status: [],
            risk_categories: [],
            locations: [],
            tags: [],
            confidence_score: [],
            matching_identifiers_min: 0,
            matching_identifiers_max: 0,
            query_ids: [],
            categories: [],
          };
          let mainentity_id = this.getSelectedEntityIds();
          if (this.filterCaseData?.length && !mainentity_id.length) {
            this.filterCaseData.forEach((el) => {
              if (el.run_intelligence) {
                countPayload.entity_ids.push(el?._id);
              }
            });
          }
          countPayload.entity_ids = countPayload.entity_ids.filter(
            (item, index) => countPayload.entity_ids.indexOf(item) === index
          );
          let data = await getEntityMenuCount(
            this.$route.query.case_id,
            countPayload
          );
          this.menuCount = data?.data;
          this.navBarItems();
          if (
            !this.entityRecords[tabType].length ||
            this.entityRecords[tabType].length < 10
          ) {
            this.updateSelectedPage(this.selectedPage);
          }
          if (!this.graphPooling) {
            this.getGraph();
          }
        }
        if (data.data.data.progress == 100) {
          flag = -1;
          this.getGraph();
          // if (this.analysis.length) {
          await this.anylysisComplete(payload);
          // }
          this.loader.screen = false;
          break;
        }
        // }
        await new Promise((resolve) => setTimeout(resolve, 10000));
        // progress+=1;
      }
    },
    async anylysisComplete(payload) {
      // this.anylysisloader = 5;
      let res;
      this.intervalID = setInterval(async () => {
        res = await getAnalysisStatus(payload);
        this.anylysisloader = res.data.data.progress;
        if (this.anylysisloader == 0) {
          this.anylysisloader = 5;
        }
        if (res.data.data.completed) {
          this.anylysisloader = 100;
          clearInterval(this.intervalID);
          // await this.handleOutputMenu();
          this.handleCustomPage(this.selectedPage, 1, "all");
        }
      }, 10000);
    },
    checkPermission,
    async handleItemChecked(item) {
      this.caseData = this.caseData.map((ele) => {
        if (ele._id === item._id) {
          // ele.checked = item.checked;
          return { ...ele, checked: item.checked };
        }
        return ele;
      });
      await this.handleOutputMenu();
      this.isChecked = this.caseData.every((item) => item.checked);
      await this.getGlobalFiltersData();
    },
    async handleSelectALL() {
      const tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.caseData = this.caseData.map((ele) => {
        return { ...ele, checked: this.isChecked };
      });
      if (!this.isChecked) {
        this.memorization = { adverse: [], news: [], offshore: [], court: [] };
        this.entityRecords[tabType] = [];
        // this.entityRecords[tabType] = [];
      }
      this.getGlobalFiltersData();
      await this.handleOutputMenu();
    },
    async getInternetData(no) {
      const tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.loading[tabType] = true;
      let payload = { case_id: this.$route.query.case_id };
      const appliedFilters = this.getAppliedFilters();
      Object.keys(appliedFilters).forEach((key) => {
        if (appliedFilters[key].length) {
          if (key == "risks") {
            payload.risk_categories = appliedFilters.risks;
          } else if (key == "confidenceScore") {
            payload.confidence_score = appliedFilters.confidenceScore;
          } else if (key == "iQueries") {
            payload.internet_queries = appliedFilters.iQueries;
            payload.query_ids = appliedFilters.iQueries;
          } else if (key == "topic_classification") {
            payload.categories = appliedFilters.topic_classification;
            this.previousTopicClassifications =
              appliedFilters.topic_classification;
          } else if (key == "names") {
            payload.persons = appliedFilters.names;
          } else if (key == "organisations") {
            payload.organisations = appliedFilters.organisations;
          } else {
            payload[key] = appliedFilters[key];
          }
        } else if (!isArray(appliedFilters[key])) {
          payload[key] = appliedFilters[key];
        }
      });
      if (this.getAllResultOnlyOnce) {
        this.getAllResultsCounts(payload, tabType);
      }

      // let customFilter = false;
      // let customFilterPayload = [];
      // if (this.globalFilterData["status"]) {
      //   this.globalFilterData["status"].map((item) => {
      //     if (item.checked && item.key != "CONFIRMED") {
      //       customFilter = true;
      //       customFilterPayload.push(item.key);
      //       customFilterPayload = [...new Set(customFilterPayload)];
      //     }
      //   });
      // }

      let entity_ids = this.getSelectedEntityIds();
      let byDefaultAllEntities = [];
      if (!entity_ids.length) {
        byDefaultAllEntities = this.caseData.map((el) => el._id);
      }
      byDefaultAllEntities = byDefaultAllEntities.filter(
        (item, index) => byDefaultAllEntities.indexOf(item) === index
      );

      byDefaultAllEntities = byDefaultAllEntities.filter(
        (item, index) => byDefaultAllEntities.indexOf(item) === index
      );

      payload.entity_ids = entity_ids.length
        ? entity_ids
        : byDefaultAllEntities;
      payload.limit = this.touchPagination.pageSize;
      payload.current_page = no;
      let internetData;
      if (this.tabs.selected === "all") {
        payload.status = [
          "CONFIRMED",
          "COMPLETED",
          "POTENTIAL",
          "IRRELEVANT",
          "UNKNOWN",
          "",
        ];
      } else if (this.tabs.selected === "confirmed") {
        payload.status = ["CONFIRMED"];
      } else if (this.tabs.selected === "potential") {
        payload.status = ["POTENTIAL"];
      } else if (this.tabs.selected === "irrelevant") {
        payload.status = ["IRRELEVANT"];
      } else if (this.tabs.selected === "unknown") {
        payload.status = ["UNKNOWN", ""];
      }

      internetData = await getinternetDataDetails(payload);

      this.entityRecords[tabType] = internetData?.data?.data;

      this.totalPages[tabType] =
        internetData?.data?.pagination_info?.total_rows;

      this.loading[tabType] = false;

      let statusFilter = true;
      if (this.globalFilterData["status"]) {
        this.globalFilterData["status"].map((item) => {
          if (item.checked && item.key == "CONFIRMED") {
            this.entityRecords.non_completed = [];
            this.totalPages[tabType] = 0;
            statusFilter = false;
            payload.status = ["CONFIRMED"];
          } else if (item.checked) {
            this.entityRecords.completed = [];
            this.totalPages[tabType] = 0;
            statusFilter = false;
          }
        });
      }
      let countPayload = { ...payload };
      if ("status" in countPayload && statusFilter) {
        delete countPayload.status;
      }
      let updatedPayload = countPayload;
      if (this.filterCaseData?.length && !entity_ids.length) {
        this.filterCaseData.forEach((el) => {
          if (el.run_intelligence) {
            updatedPayload.entity_ids.push(el?._id);
          }
        });
      }
      updatedPayload.entity_ids = updatedPayload.entity_ids.filter(
        (item, index) => updatedPayload.entity_ids.indexOf(item) === index
      );
      // this.entitySelectLoading = true;
      let countdata = await getEntityMenuCount(
        this.$route.query.case_id,
        updatedPayload
      );
      this.menuCount = countdata?.data;
      this.navBarItems();
      // this.entitySelectLoading = false;
      if (this.sourceName === "internet") {
        this.clients = this.clients.map((client) => {
          if (client.sourceName === this.sourceName) {
            return {
              ...client,
              checkAlert: this.entityRecords[tabType]?.some(
                (item) => item.risk_categories.length
              ),
            };
          }
          return client;
        });
      }
      this.loading[tabType] = false;
    },
    async getTopicClassificationData() {
      const case_id = this.$route.query.case_id;
      return getTopicClassifications(case_id);
    },
    async getGlobalFiltersData(value) {
      let tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.loading[tabType] = true;
      this.filtersLoading = true;
      try {
        const selectedEntities = this.getSelectedEntityIds();

        let byDefaultAllEntities = [];
        if (!selectedEntities.length) {
          byDefaultAllEntities = this.caseData.map((el) => el._id);
        }
        byDefaultAllEntities = byDefaultAllEntities.filter(
          (item, index) => byDefaultAllEntities.indexOf(item) === index
        );
        let filterKeys = [
          "organisation",
          "person",
          "location",
          "category",
          "risks",
          "status",
          "confidence_score",
        ];
        const payloads = filterKeys.map((item) => {
          return {
            case_id: this.$route.query.case_id,
            keyword_type: item,
            search_string: this.globalFilterData[`${item}sTerm`] || value,
            entity_ids: selectedEntities.length
              ? selectedEntities
              : byDefaultAllEntities,
            page: 1,
            // "limit": (5 * this.globalFilterData[`${item}sCurrentPage`]) || 5
          };
        });

        const qPayload = {
          case_id: this.$route.query.case_id,
          run_status: "run",
          done_status: "all",
          visited_status: "all",
          entity_ids: selectedEntities.length
            ? selectedEntities
            : byDefaultAllEntities,
          page: 1,
          limit: 5,
        };
        const promises = payloads.map((payload) => getGlobalFilters(payload));
        // promises.push(getInternetQueries(qPayload))
        promises.push(getTopicClassifications(this.$route.query.case_id));
        const iQueries = await getInternetQueries(qPayload);
        const [
          organisations,
          persons,
          locations,
          categories,
          risks,
          status,
          confidenceScore,
          topic_classification,
        ] = await Promise.all(promises);
        this.initializeTopicClassification = JSON.parse(
          JSON.stringify(topic_classification)
        );
        const queries = iQueries?.queries?.map((query) => {
          return {
            ...query,
            key: query._id,
            maskedQuery: query.maskedQuery,
          };
        });
        this.mapPreviousStateOfFilters(
          organisations.data,
          "key",
          "organisations"
        );
        this.mapPreviousStateOfFilters(persons.data, "key", "persons");
        this.mapPreviousStateOfFilters(categories.data, "key", "categories");
        this.mapPreviousStateOfFilters(risks.data, "key", "risks");
        this.mapPreviousStateOfFilters(queries, "_id", "iQueries");
        this.mapPreviousStateOfFilters(status.data, "key", "status");
        this.mapPreviousStateOfFilters(locations.data, "key", "locations");
        this.mapPreviousStateOfFilters(
          confidenceScore.data,
          "key",
          "confidenceScore"
        );
        this.mapPreviousStateOfTopicClassifications(topic_classification);
        const totalPageObj = {};
        [
          organisations,
          persons,
          locations,
          categories,
          risks,
          status,
          confidenceScore,
        ].map((item, i) => {
          totalPageObj[`${filterKeys[i]}sTotalPages`] = Math.ceil(
            item.total / 5
          );
        });
        this.globalFilterData = {
          ...this.globalFilterData,
          ...totalPageObj,
          organisations: organisations.data,
          persons: persons.data,
          locations: locations.data,
          categories: categories.data,
          risks: risks.data,
          iQueries: queries,
          status: status.data,
          confidenceScore: confidenceScore.data,
          topic_classification,
        };
        this.pageUpdated();
        this.filtersLoading = false;
        // this.loading[tabType] = false;
      } catch (err) {
        console.error(err);
        this.filtersLoading = false;
        this.loading[tabType] = false;
      }
    },
    mapPreviousStateOfTopicClassifications(topic_classification) {
      for (let item of topic_classification) {
        if (this.previousTopicClassifications.includes(item.category)) {
          item.checked = true;
        }
        if (item?.children && item?.children?.length) {
          this.mapPreviousStateOfTopicClassifications(item.children);
        }
      }
    },
    mapPreviousStateOfFilters(obj, key, filter) {
      obj.map((item) => {
        if (!this.globalFilterData[filter]) {
          this.globalFilterData[filter] = [];
        }
        this.globalFilterData[filter].map((fItem) => {
          if (fItem.key == item[key] && fItem.checked) {
            item.checked = true;
          }
        });
        return item;
      });
      this.globalFilterData[filter] = obj;
      this.globalFilterData[filter] = JSON.parse(
        JSON.stringify(this.globalFilterData[filter])
      );
    },
    async getcategoriesOption() {
      let payload = { case_id: this.$route.query.case_id };
      let categories = await getriskcategories(payload);
      this.getOptions = categories.data.risk_data;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleFilter() {
      if (!this.filter) this.getGlobalFiltersData();
      this.filter = !this.filter;
      this.graphFilterShow = !this.graphFilterShow;
    },
    toolFilterToggle() {
      this.toolFilter = !this.toolFilter;
    },
    toggleGraph() {
      // this.getGraph();
      this.graph = !this.graph;
      this.childComponent = true;
      this.graphFilterShow = !this.graphFilterShow;
    },
    graphFilterHide() {
      this.graphFilterShow = false;
    },
    expandGraph() {
      (this.filter = false), (this.childComponent = !this.childComponent);
    },
    onGroup() {
      this.isGrouped = true;
    },
    onUnGroup() {
      this.isGrouped = false;
    },
    handleSearchboxChange(value, field) {
      const tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.loading[tabType] = true;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.globalFilterData[field] = value;
        // this.filterData[field] = value
        if (!value.target) {
          this.getGlobalFiltersData(value);
        } else {
          this.getGlobalFiltersData();
        }
      }, 200);
      this.loading[tabType] = false;
    },
    handleAllCheckboxChange(event, field, sliced) {
      if (field === "topic_classification") {
        this.checkUncheckAllTopics(
          this.globalFilterData[field],
          event.target.checked
        );
      } else {
        if (sliced) {
          this.globalFilterData[field] = this.globalFilterData[field]
            .slice(0, 5)
            .map((item) => {
              item.checked = event.target.checked ? true : false;
              return item;
            });
        } else {
          this.globalFilterData[field].map((item) => {
            item.checked = event.target.checked ? true : false;
            return item;
          });
        }
      }
      this.globalFilterData = JSON.parse(JSON.stringify(this.globalFilterData));
    },
    checkUncheckAllTopics(data, value) {
      for (let item of data) {
        item.checked = value;
        if (item?.children && item?.children?.length) {
          this.checkUncheckAllTopics(item.children, value);
        }
      }
    },
    resetFilter(field, fieldterm) {
      this.globalFilterData[`${field}`] = "";
      this.globalFilterData[`${fieldterm}`] = "";
      this.getGlobalFiltersData();
      Object.keys(this.globalFilterData).map((key) => {
        if (key === "matchIdentifiers") {
          this.globalFilterData[key] = [0, 100];
        } else if (key == "topic_classification") {
          this.globalFilterData[key] = JSON.parse(
            JSON.stringify(this.initializeTopicClassification)
          );
        } else {
          if (
            this.globalFilterData[key] &&
            isArray(this.globalFilterData[key])
          ) {
            this.globalFilterData[key].map((item) => {
              item.checked = false;
              return item;
            });
          }
        }
      });
      this.previousTopicClassifications = [];
      this.globalFilterData = JSON.parse(JSON.stringify(this.globalFilterData));
      this.checkedcount = 0;
      this.updateSelectedPage(this.selectedPage);
    },
    applyAllFilters() {
      (this.touchPagination = {
        all: { page: 1, pageSize: 10, selectedPage: 1 },
        completed: { page: 1, pageSize: 10, selectedPage: 1 },
        non_completed: { page: 1, pageSize: 10, selectedPage: 1 },
        potential: { page: 1, pageSize: 10, selectedPage: 1 },
        irrelevant: { page: 1, pageSize: 10, selectedPage: 1 },
        unknown: { page: 1, pageSize: 10, selectedPage: 1 },
      }),
        this.updateSelectedPage(this.selectedPage, true);
      // this.graphFilterShow = false;
      this.toggleFilter();
    },
    async getCourtFilterData(payload, case_id) {
      let data = await getJudyRecordFilter(payload, case_id);
      data = data?.data?.data;
      let newObj = {};
      for (const key in data) {
        const newArray = data[key].map((value) => ({ key: value }));
        newObj[key] = newArray;
      }
      this.courtFilterData = newObj;
    },
    async handleSeeMore(value, field) {
      const tabType = this.getSelectedTabMatchingKey(this.tabs.selected);
      this.loading[tabType] = true;
      this.globalFilterData[`${field}CurrentPage`] =
        this.globalFilterData[`${field}CurrentPage`] + 1;
      this.globalFilterData = JSON.parse(JSON.stringify(this.globalFilterData));
      await this.getGlobalFiltersData();
      this.loading[tabType] = false;
    },
    setRange(e) {
      Vue.set(this.globalFilterData, "matchIdentifiers", e);
    },
    setMin(e) {
      this.globalFilterData.matchIdentifiers[0] = e;
    },
    setMax(e) {
      this.globalFilterData.matchIdentifiers[1] = e;
    },
    resetMatchingIdentifiers() {
      this.globalFilterData.matchIdentifiers = [0, 100];
    },
    handleCheckboxChange(event, selectedValue, field) {
      let index;
      if (field === "iQueries") {
        index = this.globalFilterData[field]
          .map((el) => el.label)
          .indexOf(selectedValue);
      } else {
        index = this.globalFilterData[field]
          .map((el) => el.key)
          .indexOf(selectedValue);
      }
      const { checked } = event.target;
      // if (
      //   [
      //     "names",
      //     "organisations",
      //     "status",
      //     "location",
      //     "risks",
      //     "confidenceScore",
      //     "iQueries",
      //   ].includes(field)
      // ) {
      //   // let index = this.globalFilterData[field].map(el => el.key).indexOf(selectedValue);
      //   Vue.set(this.globalFilterData[field][index], "checked", checked);
      // }
      Vue.set(this.globalFilterData[field][index], "checked", checked);
    },
    applyCourtFilter() {
      this.handleCustomPage(this.selectedPage, 1, "all");
    },
    resetCourtFilter() {
      this.filling_from_date = null;
      this.filling_to_date = null;
      if (this.courtFilterData) {
        Object.keys(this.courtFilterData).map((key) => {
          if (
            this.courtFilterData[key] &&
            isArray(this.courtFilterData[key]) &&
            key !== "matchIdentifiers"
          ) {
            this.courtFilterData[key].map((item) => {
              item.checked = false;
              return item;
            });
          }
        });
      }
      this.courtFilterData = JSON.parse(JSON.stringify(this.courtFilterData));
      this.courtFilterCount = 0;
      this.handleCustomPage(this.selectedPage, 1, "all");
    },
    applyToolFilter() {
      if (this.dateError) return;
      this.handleCustomPage(this.selectedPage, 1, "all");
    },
    resetToolFilter() {
      this.published_from_date = null;
      this.published_to_date = null;
      this.toolFilterCount = 0;
      this.handleCustomPage(this.selectedPage, 1, "all");
    },
    async entityRunintelligence(check) {
      if (this.loaderProgress !== 100) {
        this.$toast.error("Running intelligence please wait...");
        return false;
      }
      if (this.anylysisloader !== 100) {
        this.$toast.error("Running Analysis please wait...");
        return false;
      }
      if (!check) {
        this.reRunIntelligence = false;
        this.$refs["intelligenceModal"].showModal();
      }
      let payload = {
        request_source: "Delvium",
        case_id: this.searchedQuery.case_id,
      };
      this.runIntelligenceModal.personEntity = await this.caseData.filter(
        (el) => el.entity_type.entity === "New Person"
      );
      this.runIntelligenceModal.companyEntity = await this.caseData.filter(
        (el) => el.entity_type.entity === "New Company"
      );
      let runintelligenceData = await runIntelligenceCards(payload);
      runintelligenceData = runintelligenceData.data.data;
      runintelligenceData.employment = runintelligenceData.employment.map(
        (i) => ({ ...i, checked: false })
      );
      runintelligenceData.associatedPeople =
        runintelligenceData.associatedPeople.map((i) => ({
          ...i,
          checked: false,
        }));
      this.manualData = runintelligenceData;
      this.runIntelligenceModal.checkedPersonEntity = [];
      this.runIntelligenceModal.checkedCompanyEntity = [];
    },
    async handelPerson(type, data) {
      if (data) {
        let case_id = this.searchedQuery.case_id;
        let main_entity = await getManualData(case_id);
        let company = main_entity.data.company.map((i) => ({
          ...i,
          type: "company",
        }));
        let person = main_entity.data.person.map((i) => ({
          ...i,
          type: "person",
        }));
        main_entity = [...company, ...person];
        data = main_entity.find((i) => i.entity_id == data._id);
        if (type == "New Person") {
          for (const key in this.personData) {
            this.personData[key] = data[key];
          }
        } else {
          for (const key in this.companyData) {
            this.companyData[key] = data[key];
          }
        }
      }
      if (type == "New Person" || type == "personModal") {
        this.$refs["personModal"].showModal();
      } else {
        this.$refs["companyModal"].showModal();
      }
    },
    async handlePersonData() {
      if (this.personData.first_name == "" || this.personData.last_name == "") {
        this.$toast.error("please fill mandatory fields");
        return;
      }
      let payload = {
        case_id: this.searchedQuery.case_id,
        entity_type: "person",
        entity_data: {
          ...this.personData,
          main_entity: true,
        },
      };
      let data = await addManualData(payload);
      if (data) {
        this.personData = {
          first_name: "",
          middle_name: "",
          last_name: "",
          gender: "",
          date_of_birth: "",
        };
      }
      this.$toast.success(data.data.message);
      this.closeModal("personModal");
      await this.mainEntity();
      this.entityRunintelligence(true);
    },
    async handleCompanyData() {
      if (this.companyData.company_name == "") {
        this.$toast.error("please atleast company name");
        return;
      }
      let payload = {
        case_id: this.searchedQuery.case_id,
        entity_type: "company",
        entity_data: {
          ...this.companyData,
          main_entity: true,
        },
      };
      let data = await addManualData(payload);
      if (data) {
        this.companyData = {
          company_name: "",
          company_number: "",
          inc_date: "",
          date_of_dissolution: "",
          jurisdiction: "",
        };
      }
      this.$toast.success(data.data.message);
      this.closeModal("companyModal");
      await this.mainEntity();
      this.entityRunintelligence(true);
    },
    closeModal(type) {
      if (type == "personModal") {
        this.personData = {
          first_name: "",
          middle_name: "",
          last_name: "",
          gender: "",
          date_of_birth: "",
        };
      } else if (type == "companyModal") {
        this.companyData = {
          company_name: "",
          company_number: "",
          inc_date: "",
          date_of_dissolution: "",
          jurisdiction: "",
        };
      }
      this.$refs[type].hideModal();
    },
    async handleRunIntellegince() {
      this.loader.screen = true;
      try {
        const selectedToolState = await this.$store.getters.getSelectedToolsState;
        let toolStateForPerson = [];
        let toolStateForCompany = [];
        await this.runIntelligenceModal?.checkedPersonEntity.forEach(
          async (person) => {
            let toolState = await selectedToolState?.find(
              (el) => el.entity_id === person._id
            );
            let entity = person._id === toolState.entity_id ? person.entity : "";
            if (toolState) {
              delete toolState.case_id;
              let selectedToolsName = toolState?.features?.map(
                (f) => f.tool_name
              );
              toolState = {
                ...toolState,
                tools: selectedToolsName,
                status: "confirmed",
                jurisdiction: null,
                entity,
              };
              toolStateForPerson.push(toolState);
            }
          }
        );
        await this.runIntelligenceModal?.checkedCompanyEntity.forEach(
          async (company) => {
            let toolState = await selectedToolState?.find(
              (el) => el.entity_id === company._id
            );
            let entity =
              company._id === toolState?.entity_id ? company.entity : "";
            if (toolState) {
              delete toolState.case_id;
              let selectedToolsName = toolState?.features?.map(
                (f) => f.tool_name
              );
              toolState = {
                ...toolState,
                tools: selectedToolsName,
                status: "confirmed",
                jurisdiction: null,
                entity,
              };
              toolStateForCompany.push(toolState);
            }
          }
        );
  
        let payload = {
          username: [],
          name: toolStateForPerson || [],
          organisation_name: toolStateForCompany || [],
          domain: [],
          phone: [],
          email: [],
          socialProfiles: [],
          product_name: localStorage.getItem("package_name"),
          case_id: this.searchedQuery.case_id,
          rule_engine_set: "R2",
          associatedPeople: [],
          employment: [],
          add_relation: false,
          location: [],
          education: [],
          request_source: "Delvium",
        };
        payload.employment = this.manualData.employment.filter((i) => i.checked);
        payload.associatedPeople = this.manualData.associatedPeople.filter(
          (i) => i.checked
        );
  
        let data = await maunalRunIntelligence(payload);
        if (data) {
          await this.mainEntity();
          let countPayload = {
            entity_ids: [],
            persons: [],
            organisations: [],
            status: [],
            risk_categories: [],
            locations: [],
            tags: [],
            confidence_score: [],
            matching_identifiers_min: 0,
            matching_identifiers_max: 0,
            query_ids: [],
            categories: [],
          };
          let mainentity_id = this.getSelectedEntityIds();
          if (this.filterCaseData?.length && !mainentity_id.length) {
            this.filterCaseData.forEach((el) => {
              if (el.run_intelligence) {
                countPayload.entity_ids.push(el?._id);
              }
            });
          }
          countPayload.entity_ids = countPayload.entity_ids.filter(
            (item, index) => countPayload.entity_ids.indexOf(item) === index
          );
          // this.$refs["intelligenceModal"].hideModal();
          // this.entitySelectLoading = true;
          let data = await getEntityMenuCount(
            this.$route.query.case_id,
            countPayload
          );
          this.menuCount = data?.data;
          this.navBarItems();
          // this.entitySelectLoading = false;
          this.entityRunintelligence(true);
          clearInterval(this.intervalID);
          this.inteligenceDetails();
          // this.handleCustomPage(1,'non_completed');
          this.$toast.success("Run Intelligence Started");
        }
        this.handleOutputMenu(this.selectedPage);
        await this.mainEntity();
      } catch (err) {
        this.loader.screen = false;
        console.error(err)
      }
     
    },

    updateFrom() {
      if (this.published_from_date && this.published_to_date) {
        const from = new Date(this.published_from_date);
        const to = new Date(this.published_to_date);
        const currentDate = new Date();
        if (from > to) {
          this.dateError = "From Date must be before To Date";
          return;
        } else if (from > currentDate || to > currentDate) {
          this.dateError = "From and To Date must be before current Date";
          return;
        } else {
          this.dateError = "";
        }
      }
    },
    updateTo() {
      if (this.published_from_date && this.published_to_date) {
        const from = new Date(this.published_from_date);
        const to = new Date(this.published_to_date);
        const currentDate = new Date();
        if (from > to) {
          this.dateError = "From Date must be before To Date";
          return;
        } else if (from > currentDate || to > currentDate) {
          this.dateError = "From and To Date must be before current Date";
          return;
        } else {
          this.dateError = "";
        }
      }
    },
    selectTab(event, id) {
      let paginantionPage = 1;
      this.tabs.selected = id;
      this.tabs.list = this.tabs.list.map((el) => {
        if (el.id === "all") {
          return { ...el, iconSvgName: this.getSvgIcon() };
        }
        return el;
      });
      this.handleCustomPage(this.selectedPage, paginantionPage, id, true);
    },
    sideBarToggleHandler() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    // setStatusOfCard({ id, status }) {
    //   if (this.entityRecords.completed.includes(id)) {
    //     this.entityRecords.completed = this.entityRecords.completed.map(
    //       (el) => {
    //         if (el?._id === id) {
    //           return { ...el, status: status };
    //         }
    //         return el;
    //       }
    //     );
    //   } else {
    //     this.entityRecords.non_completed = this.entityRecords.non_completed.map(
    //       (el) => {
    //         if (el?._id === id) {
    //           return { ...el, status: status };
    //         }
    //         return el;
    //       }
    //     );
    //   }
    // },
    getSelectedTabMatchingKey(type) {
      let key =
        type === "confirmed"
          ? "completed"
          : type === "unknown"
          ? "non_completed"
          : type;
      return key;
    },

    async handleRunIntelligenceNext() {
      this.runIntelligenceNextLoading = true;
      await this.$store.dispatch("getEntitiesList");
      if (!this.isAnyFeatureAvailable) {
        await this.$store.dispatch("getSelectableFeature");
      }
      await this.manageComponentData();
      await this.$store.dispatch("getSelectedToolState");

      let ids = [];
      if (this.runIntelligenceModal?.checkedCompanyEntity.length) {
        let filterids =
          await this.runIntelligenceModal.checkedCompanyEntity.map(
            (el) => el._id
          );
        ids = [...ids, ...filterids];
      }

      if (this.runIntelligenceModal?.checkedPersonEntity.length) {
        let filterIds = await this.runIntelligenceModal.checkedPersonEntity.map(
          (el) => el._id
        );
        ids = [...ids, ...filterIds];
      }
      let filterData = [];
      if (ids.length) {
        await this.entitiesList.forEach((el) => {
          if (ids.includes(el.entity_id)) {
            filterData.push(el);
          }
        });
      }

      await this.$store.commit("SET_ENTITIES_LIST", filterData);
      await this.manageComponentData();
      await this.$store.dispatch("getSelectedToolState");
      // this.handleClearAll();
      // this.showBuildQuery = false;
      if (this.main_entity.lenght < 5) {
        this.showMainEntityMoreList = false;
      }
      // await this.$store.dispatch("getSelectedToolState");

      this.$refs["selectAndAnalysisModal"].showModal();
      this.$store.commit("SET_SELECTED_COMPONENT_KEY");
      this.runIntelligenceNextLoading = false;
      this.$refs["intelligenceModal"].hideModal();
    },
    async getMainEntity() {
      try {
        this.main_entity_loader = true;
        let data = this.getCaseFromSessionStorage();
        if (data?.neo_case_id) {
          let case_id = data?.neo_case_id;
          try {
            let main_entity = await getManualData(case_id);
            let company = main_entity?.data?.company.map((i) => ({
              ...i,
              type: "company",
            }));
            let person = main_entity?.data?.person.map((i) => ({
              ...i,
              type: "person",
            }));
            if (company && person) {
              main_entity = [...company, ...person];
              main_entity.sort(
                (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
              );
            }

            if (main_entity && main_entity.length) {
              if (main_entity[0]?.company_name)
                sessionStorage.setItem(
                  "case_name",
                  main_entity[0]?.company_name
                );
              else {
                sessionStorage.setItem("case_name", main_entity[0]?.first_name);
              }
            }
            this.main_entity = main_entity;
            if (this.main_entity.length < 5) {
              this.showMainEntityMoreList = false;
            }
            this.main_entity_loader = false;
            this.manageComponentData();
            return main_entity;
          } catch (err) {
            this.$toast.error(
              err?.detail ||
                err?.response?.data?.detail ||
                "Somthing went wrong"
            );
          }
        } else {
          this.main_entity = [];
          this.main_entity_loader = false;
          return this.main_entity;
        }
      } catch (err) {
        this.$toast.error(
          err?.detail || err?.response?.data?.detail || "Somthing went wrong"
        );
      }
    },
    isInternetToolSelected(currentSelectedPosition) {
      let selected = false;
      if (this.main_entity[currentSelectedPosition].analysis.length > 0) {
        selected = this.main_entity[
          currentSelectedPosition
        ].internetSearch.some((el) => el.checked === true);
      }
      return selected;
    },
    async nextEntity() {
      this.$store.dispatch("nextEntity");
    },
    previousEntity() {
      this.$store.dispatch("previousEntity");
    },

    // To manage the elect query and output component data
    async manageComponentData() {
      await this.$store.dispatch("manageComponentData");
    },
    async getSelectableFeature() {
      try {
        let features = [];
        let internetSearch = [];
        let analysis = [];

        if (this.licenseIds.length) {
          try {
            const data = await getAllFeatures(this.licenseIds);
            features = data?.data?.features || [];
            analysis = data?.data?.analyis || [];
          } catch (err) {
            this.$toast.error(
              err.response.data.detail || "somthing went wrong"
            );
          }
        }

        let isInternetSearch = features.find(
          (f) => f.feature_name === "internet_search"
        );
        this.internetFeatureForPayload = isInternetSearch;

        if (isInternetSearch) {
          try {
            const response = await getInternetSearchCategory();
            internetSearch = response?.data?.data?.map((item) => ({
              category_id: item.category_id,
              category_name: item.category_name,
              label: item.category_name,
              checked: false,
              id: item.category_id,
            }));
          } catch (err) {
            this.$toast.error(
              err?.response?.data?.detail || "Somthing went wrong"
            );
          }
        }

        if (features?.length) {
          features = features.map((f) => ({
            ...f,
            checked: false,
            id: f.feature_name,
            label: f.feature_name,
          }));
        }

        if (analysis?.length) {
          analysis = analysis.map((el) => ({
            id: el,
            value: el,
            label: this.getAnalysisMenuName(el),
            checked: false,
          }));
        }

        features = features.filter((f) => f.feature_name !== "internet_search");

        this.main_entity_loader = false;
        return { features, internetSearch, analysis };
      } catch (err) {
        console.error(err);
        this.main_entity_loader = false;
      }
    },
    getAnalysisMenuName(name) {
      if (name === "analyzer__entity_recognition") {
        return "Entity Recognition";
      } else if (name === "analyzer__topic_classification") {
        return "Topic Classification";
      } else if (name === "analyzer__core_risk_categorisation") {
        return "Risk Categorisation";
      }
    },

    async postEntityData(entityData) {
      let payload = {
        case_id: sessionStorage.getItem("neo_case_id") || "",
        features: [],
        category: [],
        entity_id: entityData?.entity_id || "",
        config: {
          ner: false,
          topic_classification: false,
          risk_categorisation: false,
          esg_categorisation: false,
        },
      };
      const isAnySelectOutputChecked = entityData?.selectOutputs.some(
        (el) => el.checked
      );
      const isAnyInternetSearchChecked = entityData?.internetSearch.some(
        (el) => el.checked
      );

      if (!isAnySelectOutputChecked && !isAnyInternetSearchChecked) {
        return;
      }
      if (isAnySelectOutputChecked) {
        payload.features = this.getPayload(
          entityData.selectOutputs,
          "selectOutput"
        );
      }
      const isAnalysisData = entityData?.analysis.some((el) => el.checked);
      if (isAnalysisData) {
        payload.config.ner = this.getConfigValue(
          entityData?.analysis.find((el) => el.id === "entityRecognition")
        );
        payload.config.topic_classification = this.getConfigValue(
          entityData?.analysis.find((el) => el.id === "topicClassification")
        );
        payload.config.risk_categorisation = this.getConfigValue(
          entityData?.analysis.find((el) => el.id === "riskCategorisation")
        );
        payload.config.esg_categorisation = this.getConfigValue(
          entityData?.analysis.find((el) => el.id === "riskCategorisation")
        );
      }
      if (isAnyInternetSearchChecked) {
        payload.category = this.getPayload(
          entityData.internetSearch,
          "internetSearch"
        );
        payload.features.push(this.internetFeatureForPayload);
      }
      let dataPost = false;
      try {
        const data = await postEachEntitySelectedToolData(payload);
        if (data?.data?.status) {
          dataPost = true;
        }
      } catch (err) {
        console.error(err);
        this.$toast.error(
          err?.response?.data?.detail || "Something went wrong"
        );
        this.saveEntityDataLoader = false;
        dataPost = false;
      }
      return dataPost;
    },
    isToolSelected(currentSelectedPosition) {
      let isAnyToolSelected = false;
      if (this.main_entity[currentSelectedPosition].selectOutputs.length > 0) {
        isAnyToolSelected = this.main_entity[
          currentSelectedPosition
        ].selectOutputs.some((el) => el.checked === true);
      }
      if (
        !isAnyToolSelected &&
        this.main_entity[currentSelectedPosition].internetSearch.length > 0
      ) {
        isAnyToolSelected = this.main_entity[
          currentSelectedPosition
        ].internetSearch.some((el) => el.checked === true);
      }
      return isAnyToolSelected;
    },
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
    handleClearAll() {
      // this.selectedComponentKey = 0;
      this.manageComponentData();
    },
    async saveEntity() {
      await this.$store.dispatch("saveEntity", { type: "runIntelligence" });
      await this.$store.dispatch("getSelectedToolState");
      this.reRunIntelligence = true;
      this.$refs["selectAndAnalysisModal"].hideModal();
      this.handleRunIntellegince();
      await this.$store.dispatch("getEntitiesList");
      if (!this.isAnyFeatureAvailable) {
        await this.$store.dispatch("getSelectableFeature");
      }
      await this.manageComponentData();
    
      // if (this.main_entity.length) {
      //   if (this.main_entity.length === 1) {
      //     let isAnyToolSelected = this.isToolSelected(0);

      //     if (!isAnyToolSelected) {
      //       this.$toast.error("Please select at least one tool.");
      //       return;
      //     }
      //   }
      //   if (this.main_entity.length === 1) {
      //     const currentSelectedPosition = this.main_entity
      //       .map((el) => el.selected)
      //       .indexOf(true);
      //     await this.postEntityData(this.main_entity[currentSelectedPosition]);
      //   }
      //   let data = this.getCaseFromSessionStorage();
      //   let customPayload = {
      //     case_name:
      //       this.main_entity[0].first_name || this.main_entity[0].company_name,
      //     neo_case_id: data.id,
      //   };
      //   updateCaseName(customPayload, data.neo_case_id);
      // this.$router.push({
      //   name: "entity-details",
      //   params: { tool: "Entity-Details" },
      //   query: {
      //     case_id: this.$route.query.case_id || data.neo_case_id,
      //     name:
      //       this.main_entity[0].first_name ||
      //       this.main_entity[0].company_name,
      //   },
      // });
      // }
      // this.$refs["selectAndAnalysisModal"].hideModal();
      // this.reRunIntelligence = true;
      // this.$refs["intelligenceModal"].showModal();
      // if (this.main_entity.length) {
      //   let data = this.getCaseFromSessionStorage();
      //   let custmPayload = {
      //     case_name:
      //       this.main_entity[0].first_name || this.main_entity[0].company_name,
      //     neo_case_id: data.id,
      //   };
      //   updateCaseName(custmPayload, data.neo_case_id);
      //   this.$router.push({
      //     name: "entity-details",
      //     params: { tool: "Entity-Details" },
      //     query: {
      //       case_id: this.$route.query.case_id || data.neo_case_id,
      //       name:
      //         this.main_entity[0].first_name ||
      //         this.main_entity[0].company_name,
      //     },
      //   });
      // }
    },
    toggleMoreList(value) {
      this.$store.dispatch("toggleMoreList", value);
      // this.showMainEntityMoreList = value;
    },
    getPayload(data, type) {
      switch (type) {
        case "selectOutput": {
          let selectOutputData = data
            .filter((el) => el.checked)
            .map((item) => omit(item, ["id", "checked", "label"]));
          return selectOutputData;
        }
        case "internetSearch": {
          let internetSearchData = data
            .filter((el) => el.checked)
            .map((item) => omit(item, ["id", "checked", "label"]));
          return internetSearchData;
        }
      }
    },
    async getAllResultsCounts(source, s) {
      const tabType = this.getSelectedTabMatchingKey(s);
      this.loading[tabType] = true;
      try {
        let payload = {
          case_id: this.$route.query.case_id,
          ...source,
        };
        const selectedEntities = this.getSelectedEntityIds();
        let data;
        let internetData;
        let byDefaultAllEntities = [];
        if (!selectedEntities.length) {
          byDefaultAllEntities = this.caseData.map((el) => el._id);
        }
        byDefaultAllEntities = byDefaultAllEntities.filter(
          (item, index) => byDefaultAllEntities.indexOf(item) === index
        );
        payload.entity_ids = selectedEntities.length
          ? selectedEntities
          : byDefaultAllEntities;
        if (this.published_from_date && this.published_to_date) {
          (payload.published_from_date = this.published_from_date),
            (payload.published_to_date = this.published_to_date);
        }

        if (this.sourceName !== "internet") {
          payload.status = [
            "COMPLETED",
            "POTENTIAL",
            "IRRELEVANT",
            "UNKNOWN",
            "",
            "CONFIRMED",
          ];
          data = await getEntityIdentifiers(payload);
          this.totalPages["all"] = data.data.pagination.total_rows;

          payload.status = ["CONFIRMED"];
          data = await getEntityIdentifiers(payload);
          this.totalPages["completed"] = data.data.pagination.total_rows;

          payload.status = ["POTENTIAL"];
          data = await getEntityIdentifiers(payload);
          this.totalPages["potential"] = data.data.pagination.total_rows;

          payload.status = ["IRRELEVANT"];
          data = await getEntityIdentifiers(payload);
          this.totalPages["irrelevant"] = data.data.pagination.total_rows;

          payload.status = ["", "UNKNOWN"];
          data = await getEntityIdentifiers(payload);
          this.totalPages["non_completed"] = data.data.pagination.total_rows;
        } else {
          payload.status = [
            "COMPLETED",
            "POTENTIAL",
            "IRRELEVANT",
            "UNKNOWN",
            "",
            "CONFIRMED",
          ];
          internetData = await getinternetDataDetails(payload);
          this.totalPages["all"] =
            internetData?.data?.pagination_info?.total_rows;

          payload.status = ["CONFIRMED"];
          internetData = await getinternetDataDetails(payload);
          this.totalPages["completed"] =
            internetData?.data?.pagination_info?.total_rows;

          payload.status = ["POTENTIAL"];
          internetData = await getinternetDataDetails(payload);
          this.totalPages["potential"] =
            internetData?.data?.pagination_info?.total_rows;

          payload.status = ["IRRELEVANT"];
          internetData = await getinternetDataDetails(payload);
          this.totalPages["irrelevant"] =
            internetData?.data?.pagination_info?.total_rows;

          payload.status = ["", "UNKNOWN"];
          internetData = await getinternetDataDetails(payload);
          this.totalPages["non_completed"] =
            internetData?.data?.pagination_info?.total_rows;
        }
        // this.loading[tabType] = false;
        this.getAllResultOnlyOnce = false;
      } catch (err) {
        console.error(err);
        this.loading[tabType] = false;
      }
    },
    setNeoCaseIdIntoSessionStorage() {
      let neoCaseId = sessionStorage.getItem("neo_case_id");
      if (!neoCaseId) {
        sessionStorage.setItem("neo_case_id", this.$route?.query?.case_id);
      } else if (neoCaseId !== this.$route?.query?.case_id) {
        sessionStorage.setItem("neo_case_id", this.$route?.query?.case_id);
      }
    },
    async getClientId() {
      try {
        const data = await getCaseDetail(this.$route?.query?.case_id);
        return data?.data?.client_id;
      } catch (err) {
        this.$toast.error(err?.response?.data?.detail || "Somthing went wrong");
      }
    },
    async setSaasToken() {
      try {
        const clientId = await this.getClientId();
        const token = VueCookies.get("token");
        if (token && clientId) {
          const response = await getSaasToken(clientId, token);
          VueCookies.set("saas-token", response.data.token);
        }
      } catch (err) {
        this.$toast.error(err?.response?.data?.detail || "Somthing went wrong");
      }
    },
    async fetchLicenseIds() {
      try {
        const licenseData = await getLicenseIds();
        this.licenseIds =
          licenseData?.data?.license_details?.map((el) => el?.license_id) || [];
      } catch (err) {
        this.$toast.error(err?.response?.data?.detail || "Somthing went wrong");
      }
    },
    getConfigValue(data) {
      return data && data.checked;
    },
    async handleSaveSelectedData(type, value, id) {
      const payload = { type, value, id };
      // this.$store.dispatch("saveSelectedData", payload);
      await this.$store.dispatch("saveSelectedData", payload);
      // flag to know when we call the api on next
      this.$store.commit("SET_IS_ENTITY_DATA_UPDATED", true);
      // this.isEntityDataUpdated = true;
    },
    async handleOutputMenu(alreadyselectedPage) {
      // let mainentity_id = this.getSelectedEntityIds();
      // this.entitySelectLoading = true;
      let countPayload = {
        case_id: this.$route.query.case_id,
        entity_ids: [],
        source_name: this.sourceName,
        current_page: 1,
        limit: 10,
        matching_identifiers_min: 0,
        matching_identifiers_max: 100,
      };
      let mainentity_id = await this.getSelectedEntityIds();

      if (this.caseData?.length && !mainentity_id.length) {
        this.caseData.forEach((el) => {
          if (el.run_intelligence) {
            countPayload.entity_ids.push(el?._id);
          }
        });
      } else {
        countPayload.entity_ids = mainentity_id;
      }
      countPayload.entity_ids = countPayload.entity_ids.filter(
        (item, index) => countPayload.entity_ids.indexOf(item) === index
      );
      let updatedPayload = countPayload;
      let countdata = await getEntityMenuCount(
        this.$route.query.case_id,
        updatedPayload
      );
      this.menuCount = countdata?.data;
      let outputMenu = [];
      for (const el in this.menuCount) {
        if (el === "opencorporates") {
          outputMenu.push({
            id: 1,
            name: "Corporate Records",
          });
        } else if (el === "dnb") {
          outputMenu.push({
            id: 2,
            name: "Company Credit",
          });
        } else if (el === "adverse-media") {
          outputMenu.push({
            id: 3,
            name: "Adverse/PEPs/Sanctions",
          });
        } else if (el === "offshoreleaks") {
          outputMenu.push({
            id: 4,
            name: "Offshore Records",
          });
        } else if (el === "court-records") {
          outputMenu.push({
            id: 5,
            name: "Court Records",
          });
        } else if (el === "news") {
          outputMenu.push({
            id: 6,
            name: "News",
          });
          // this.selectedPage = 6;
        } else if (el === "search-engine") {
          outputMenu.push({
            id: 7,
            name: "Internet Search",
          });
        }
      }
      outputMenu = outputMenu.sort((a, b) => a.id - b.id);
      if (!alreadyselectedPage) {
        this.selectedPage = outputMenu[0]?.id;
      }
      // else {
      //   this.selectedPage = alreadyselectedPage;
      // }
    },
    async deleteEntity(entity_id) {
      this.$store.dispatch("deleteEntity", entity_id);
      this.handleDeleteEntity(entity_id);
      // await this.getGlobalFiltersData();
      // try {
      //   const currentSelectedPosition = this.entitiesList
      //     .map((el) => el.selected)
      //     .indexOf(true);
      //   const currentClickedPosition = this.entitiesList.findIndex(
      //     (el) => el.entity_id === entity_id
      //   );
      //   let deletedPos = await this.findPos(entity_id);
      //   if (deletedPos || deletedPos === 0) {
      //     deletedPos =
      //       deletedPos === this.entitiesList.length - 1
      //         ? deletedPos - 1
      //         : deletedPos;
      //   }
      //   let deleteData = await deleteManualData(entity_id);
      //   if (deleteData) {
      //     if (this.entitiesList.length == 1) {
      //       sessionStorage.removeItem("case_name");
      //       this.$forceUpdate();
      //       this.$router.push({
      //         name: "add-multientities",
      //         query: {
      //           package_id: this.$route.query.package_id,
      //           client_id: this.$route.query.client_id,
      //           t: new Date().getTime(),
      //         },
      //       });
      //     } else {
      //       this.entitiesList.sort(
      //         (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
      //       );
      //       if (this.entitiesList.length) {
      //         sessionStorage.setItem(
      //           "case_name",
      //           this.entitiesList[0]?.company_name ||
      //             this.entitiesList[0]?.person
      //         );
      //       }
      //     }
      //     this.$toast.success("Deleted Successfully");
      //     await this.$store.dispatch("getEntitiesList");
      //     this.$store.commit("SET_FEATURE_DATA_IN_ENTITIES_LIST");

      //     if (this.entitiesList?.length === 1) {
      //       this.showBuildQuery = true;
      //       this.$store.commit("SET_SELECT_ENTITY", 0);
      //       this.updateComponentData();
      //     } else {
      //       this.updateEntitySelectedPosition(
      //       deletedPos,
      //       currentSelectedPosition,
      //       currentClickedPosition
      //     );
      //       this.showBuildQuery = false;
      //     }

      //     // remove the more list option form entity ribbon
      //     if (this.entitiesList.length < 5) {
      //       this.showMainEntityMoreList = false;
      //     }
      //   } else {
      //     this.$toast.warning("Automated Entity is not deleted");
      //   }
      // } catch (err) {
      //   console.error("error", err);
      //   this.$toast.error("Something went wrong");
      // }
    },
  },
};

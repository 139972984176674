<template>
  <div
    class="flex h-full bg-white mt-3 border-slate-700 p-4 rounded-2xl parent overflow-y-auto scroll-bar"
    :style="`height: 82vh;`"
  >
    <div class="flex h-full w-full" v-if="!featureLoading && !createCaseLoader">
      <person-company-tab
        v-if="isAnyFeatureAvailable"
        :mainColor="mainColor"
        @personSearch="personSearch"
        @companySearch="companySearch"
      />
      <div v-else class="flex justify-center items-center h-full w-full">
          <span class="pl-4 text-2xl"
            >Please contact your system administrator.</span
          >
      </div>
    </div>
    <div v-else class="flex justify-center items-center h-full w-full">
      <v-loader />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
import PersonCompanyTab from "../../components/Tabs/person-company.vue";

export default {
  name: "add-multiEntities",
  components: {
    PersonCompanyTab,
    "v-loader": Loader,
  },
  data() {
    return {
      mainColor: "",
    };
  },
  created() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
  },
  async mounted() {
    const caseDetails = this.getCaseFromSessionStorage();
    if (!this.isAnyFeatureAvailable) {  
      await this.$store.dispatch("getSelectableFeature");
    }
    if (
      !caseDetails.id &&
      !caseDetails.neo_case_id &&
      this.isAnyFeatureAvailable
    ) {
        const casePayload = { package_id: this.$route.query.package_id };
        this.$store.dispatch("createNewCase", casePayload);
    }
  },
  computed: {
    featureLoading() {
      return this.$store.getters.getFeatureLoader;
    },
    createCaseLoader() {
        return this.$store.getters.getCreateCaseLoading;
    },
    isAnyFeatureAvailable() {
      return this.$store.getters.getIsAnyFeatureAvailable;
    },
  },
  methods: {
    companySearch() {
      this.$router.push({
        name: "search-company-records",
        params: { type: "company-search" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          type: "company",
          mode: "add",
        },
      });
    },
    personSearch() {
      this.$router.push({
        name: "add-person",
        params: { type: "person", mode: "add" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          t: new Date().getTime(),
          type: "person",
          mode: "add",
        },
      });
      //   this.$router.push({
      //     name: "add-multientities",
      //     params: { type: "person", mode: "add" },
      //     query: {
      //       package_id: this.$route.query.package_id,
      //       client_id: this.$route.query.client_id,
      //       t: new Date().getTime(),
      //     },
      //   });
    },
    saveCaseInSessionStorage(data) {
      sessionStorage.setItem("id", data?.id);
      sessionStorage.setItem("neo_case_id", data?.neo_case_id);
    },
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
  },
};
</script>

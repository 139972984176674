<template>
  <div>
    <div class="items-start flex flex-row" v-if="isAnyEntityAvailable">
      <div
        v-if="!fromSelectQueries"
        class=""
        @blur="
          () => {
            this.addbutton = false;
          }
        "
        tabindex="1"
      >
        <div
          class="flex items-center dynamic_color px-3 rounded-l-lg cursor-pointer"
          :class="fromSelectQueries ? 'h-10 border-2': 'h-9 mt-0.5'"
          
          @click="
            () => {
              this.addbutton = !this.addbutton;
            }
          "
        >
          <Plus :fill="`#ffffff`" />
        </div>
        <div
          v-if="addbutton"
          class="absolute top-1 left-2 z-50 flex justify-between rounded-lg items-center shadow-lg px-4 py-2 w-60 bg-white"
         
        >
          <div
            class="flex justify-center items-center cursor-pointer"
            @click="personSearch"
          >
            <UserIcon :fill="mainColor" /><span class="mx-1">Person</span>
          </div>
          <div class="border h-6"></div>
          <div
            class="flex justify-center items-center cursor-pointer"
            @click="companySearch"
          >
            <CompanyIcon :fill="mainColor" /><span class="mx-2">Company</span>
          </div>
        </div>
      </div>
      <ul
        id="main-entity-ul"
        class="text-sm font-medium text-center text-gray-500 grid grid-cols-6 h-10 items-center"
      >
        <li
          v-for="(item, index) in mainCasedata"
          :key="index"
          class="grid-col-2"
        >
          <div
            class="flex justify-evenly items-center px-2 py-1 text-gray-900 bg-white border-r-2"
            :class="{
              'rounded-r-lg': getStyleClass(index),
              'bg-blue-200': item.selected,
              'border-t-2 border-b-2': fromSelectQueries,
            }"
          >
            <span
              class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center px-1"
              v-if="item?.type === 'person'"
            >
              <UserIcon :fill="mainColor" />
            </span>
            <span
              class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center px-1"
              v-else
            >
              <CompanyIcon :fill="mainColor" />
            </span>
            <label
              class="break-words px-1"
              :title="item?.entity"
              :for="`userid_${index}`"
            >
              {{ getName(item) }}</label
            >
            <!-- <span class="mx-1 cursor-pointer">
              <EditIcon :fill="mainColor" @click="editEntity(item)" />
            </span> -->
            <span class="mx-1 cursor-pointer px-1" v-if="!item.run_intelligence">
              <DeleteIcon :fill="mainColor" @click="deleteEntity(item)" />
            </span>
          </div>
        </li>
        <li class="w-full dropdown_parent">
          <button
            @click="toggleList"
            v-show="multiData.length > 0"
            ref="dropdown"
            class="w-full text-red-800 bg-white flex justify-around items-center h-10 border border-gray-200 rounded-r-lg border-r-2"
          >
            <div class="flex relative">
              <span
                class="z-0 w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center"
              >
                <UserIcon :fill="mainColor" />
              </span>
              <span
                class="absolute border-2 border-white left-5 z-10 w-7 h-7 p-1 rounded-full bg-red-200 flex justify-center items-center"
              >
                <CompanyIcon :fill="mainColor" />
              </span>
            </div>
            + &nbsp;{{ multiData.length }} More
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              color="black"
              size="xs"
            />
          </button>
          <ul
            v-if="showList || showMoreList"
            class="dropdown_content text-sm font-medium text-center text-gray-500 divide-y divide-gray-200 border border-gray-200 shadow h-auto"
          >
            <li
              v-for="(item, index) in multiData"
              :key="index"
              class="w-42 h-9"
              :class="{ 'bg-blue-200': item.selected }"
            >
              <div
                class="flex justify-between items-center w-full px-2 py-1 text-gray-900 bg-white hover:bg-red-100 position-relative"
                :class="{
                  'rounded-r-lg': getStyleClass(index),
                  'bg-blue-200': item.selected,
                }"
              >
                <span
                  class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center px-1"
                  v-if="item.categories_type === 'officers'"
                >
                  <UserIcon :fill="mainColor" />
                </span>
                <span
                  class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center px-1"
                  v-else
                >
                  <CompanyIcon :fill="mainColor" />
                </span>
                <span :title="item.first_name" :for="`userid_${index}`">
                  {{
                    item.company_name
                      ? item.company_name.length > 5
                        ? `${item.company_name.substr(0, 5)}...`
                        : item.company_name
                      : item.first_name.length > 5
                      ? `${item.first_name.substr(0, 5)}...`
                      : item.first_name
                  }}</span
                >
                <!-- <span class="mx-2 cursor-pointer">
                  <EditIcon :fill="mainColor" @click="editEntity(item)" />
                </span> -->
                <span class="mx-2 cursor-pointer px-1">
                  <DeleteIcon :fill="mainColor" @click="deleteEntity(item)" />
                </span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import { getjurisdictionList } from "../../services.js"
import UserIcon from "../../assets/svg/user.svg";
import CompanyIcon from "../../assets/svg/company.svg";
// import DeleteIcon from "../../assets/svg/delete.svg";
import DeleteIcon from "../../assets/svg/deleteIcon.svg";
// import EditIcon from "../../assets/svg/edit_line.svg";
import Plus from "../../assets/svg/plus.svg";
export default {
  name: "main-entity",
  components: {
    CompanyIcon,
    UserIcon,
    DeleteIcon,
    // EditIcon,
    Plus,
  },
  props: {
    main_entity: Array,
    showMoreList: {
      type: Boolean,
      default: false,
    },
    fromSelectQueries: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainCasedata() {
      if (this.main_entity.length < 5) {
        return this.main_entity; //0,1,2,3,4
      } else {
        return this.main_entity.filter((ele, index) => index <= 4); //0,1,2,3,4
      }
    },
    multiData() {
      return this.main_entity.filter((ele, index) => index > 4);
    },
    isAnyEntityAvailable() {
      return !!this.main_entity.length;
    }
  },
  data() {
    return {
      mainColor: {},
      addbutton: false,
      showList: false,
    };
  },
  methods: {
    editEntity(item) {
      if (item.type == "person") {
        this.$router.push({
          name: "add-multientities",
          params: { type: "person", mode: "edit" },
          query: {
            entity_id: item.entity_id,
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
          },
        });
      } else {
        this.$router.push({
          name: "add-multientities",
          params: { type: "company", mode: "edit" },
          query: {
            entity_id: item.entity_id,
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
          },
        });
      }
    },
    closeDropdown() {
      this.addbutton = false;
    },
    companySearch() {
      this.addbutton = false;
      this.$router.push({
        name: "search-company-records",
        params: { type: "company-search", mode: "add" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
        },
      });
    },
    personSearch() {
      this.addbutton = false;
      this.$router.push({
        name: "add-person",
        params: { type: "person", mode: "add" },
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          t: new Date().getTime(),
          type: "person",
          mode: "add",
        },
      });
    },
    getName(item) {
      if (item.company_name)
        return item?.company_name.length > 5
          ? `${item.company_name.substr(0, 5)}...`
          : item?.company_name;
      else
        return item?.first_name.length > 5
          ? `${item.first_name.substr(0, 5)}...`
          : item?.first_name;
    },
    deleteEntity(item) {
      this.$emit("deleteEntity", item.entity_id);
    },
    toggleList() {
      this.showList = !this.showList;
      this.$emit("toggleMoreList", this.showList);
    },
    getStyleClass(index) {
      if (
        index === this.mainCasedata.length - 1 &&
        this.multiData.length <= 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  async mounted() {
    // console.log(this.showMoreList)
  },
  created() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/mixins/mixins.scss";
@import "../../assets/scss/color.scss";
@include tableComponent;
.searchParent {
  border-radius: 10px;
  border: 1px solid #c8d2df;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 1.75rem 1.5rem;
}
.heading {
  color: #707070;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.dynamic_color {
  background: $lightray_product_color;
}
.button_red {
  border-color: $lightray_product_color;
  border-radius: 6px;
  background: $lightray_product_color;
}
.oops {
  color: #333;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}
.contavt {
  color: #8c8c8c;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.navbar_parent {
  ul#main-entity-ul:hover {
    background-color: transparent !important;
  }
  li {
    cursor: pointer !important;
    max-width: 220px;
  }
}

.dropdown_parent {
  position: relative !important;
}

.dynamic_color {
  background: $lightray_product_color;
}

.dropdown_content {
  position: absolute !important;
  top: 100% !important;
  left: 0%;
  z-index: 1000 !important;
  width: inherit;
}
</style>

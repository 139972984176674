<template>
  <div class="custom_card break-all border rounded-lg p-4  mx-2 mb-2 bg-white" :style="isShrink ?  '':''">
    <div class="image-section w-28 bg-slate-700 mr-2" v-if="getImage">
      <img :src="getImageLink" class="w-28 h-auto" />
    </div>
    <div class="w-full ">
      <div v-if="!isShrink" class="data-section w-full">
        <div class="header-section w-full  mb-1 flex justify-between">
          <div>
            <a class="text-primary header-link" :href="getlink" target="_blank" :title="item.type=='media' ? item.entity : item.title">{{ getTitle }}</a>
          </div>
          <div class="flex">
            <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="getIndividualData"
              :mainEntity="mainEntity" :sourceName="this.sourceName" :confidence="true" />
            <div class="mx-1 label-box  dropdown_parent" :class="getStatus">
              <span class="label" @click="this.toogleDropdown" @blur="closeDropdown" tabindex="1">
                <MinusIcon v-if="getStatus == 'unknown'" class="cursor-pointer" />
                <Check v-if="getStatus == 'confirmed'" class="cursor-pointer" />
                <Question v-if="getStatus == 'potential'" class="cursor-pointer" />
                <Cross v-if="getStatus == 'irrelevant'" class="cursor-pointer" />
              </span>
              <ul v-if="showList" class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card ">
                <li @mousedown="selectDropdown" name="confirmed"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="confirmed" class="" style="color: #67B52B">Confirmed</span>
                  <span name="confirmed" class="badge " style="background:#F0F8EA ">
                    <Check name="confirmed" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="potential"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="potential" class="" style="color: #FC6713">Potential</span>
                  <span name="potential" class="badge " style="background:rgba(252, 103, 19, 0.11) ">
                    <Question name="potential" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="irrelevant"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="irrelevant" class="" style="color: #EB3131">Irrelevant</span>
                  <span name="irrelevant" class="badge " style="background:#FBEDED">
                    <Cross name="irrelevant" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="unknown" class="p-2 flex items-center justify-between">
                  <span name="unknown" class="" style='color:#8D8D8D'>unknown</span>
                  <span name="unknown" class="badge" style="background:#D7D7D7">
                    <MinusIcon name="unknown" />
                  </span>
                </li>
              </ul>
            </div>
            <div class="mx-1">
              <Exclamation class="cursor-pointer" :fill="riskcolor" />
            </div>
            <div class="mx-1">
              <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="getIndividualData"
                :mainEntity="mainEntity" :sourceName="this.sourceName"  />
            </div>
          </div>
        </div>
        <div v-if="name == 'internetsearch'||name == 'news' ||name == 'court'  " class="linkHeader w-1/2 truncate">
          <span class="text-sm faded">{{ `${getlink.substr(0,80)}...` }}</span>
        </div>
        <div class="description-section ">
          <p class="text-black text-sm">{{ getdescription }}</p>
        </div>
        <div class="flex flex-wrap items-center">
            <div v-for="item, indx in getRiskCategories" :key="indx" class="label mx-1 mt-2 rounded-full"
              :class="checkCategories(item)">
              <span>{{ item }}</span>
            </div>
            <span v-if="showSeeMore" @click="toggleSeeMore" class="f-title cursor-pointer pl-2">
              {{ this.seeMore ? 'Show Less' : 'Load More' }}</span>
          </div>
      </div>

      <div v-if="isShrink" class="flex" >
        <div class="flex flex-col flex-grow-1 w-full">
          
          <div class="header-section  mb-2 ">
            <a class="text-primary header-link" :href="getlink" target="_blank">{{ formatText(getlink, isShrink ? 30 : 55)
            }}</a>
          </div>
          <div v-if="name == 'internetsearch'||name == 'news' ||name == 'court'  " class="linkHeader  header-link">
          <span class="text-sm faded">{{ `${getlink.substr(0,80)}...` }}</span>
        </div>
        <div class="description-section ">
          <p class="text-black text-sm">{{ getdescription }}</p>
        </div>
          <div class="flex flex-wrap items-center">
            <div v-for="item, indx in getRiskCategories" :key="indx" class="label mx-1 mt-2 rounded-full"
              :class="checkCategories(item)">
              <span>{{ item }}</span>
            </div>
            <span v-if="showSeeMore" @click="toggleSeeMore" class="f-title cursor-pointer pl-2">
              {{ this.seeMore ? 'Show Less' : 'Load More' }}</span>
          </div>
        </div>
        <div>
          <div class="flex flex-col justify-between cst-buttons-height items-center pl-2">
            <div class="mx-1 my-1 label-box " :class="confidenceColor">
              <span>{{ this.item?.identifiers.length || '0' }}</span>
            </div>
            <div class="mx-1 my-1 label-box  dropdown_parent" :class="getStatus">
              <span class="label" @click="this.toogleDropdown" @blur="closeDropdown" tabindex="1">
                <MinusIcon v-if="getStatus == 'unknown'" class="cursor-pointer" />
                <Check v-if="getStatus == 'confirmed'" class="cursor-pointer" />
                <Question v-if="getStatus == 'potential'" class="cursor-pointer" />
                <Cross v-if="getStatus == 'irrelevant'" class="cursor-pointer" />
              </span>
              <ul v-if="showList" class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card ">
                <li @mousedown="selectDropdown" name="confirmed"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="confirmed" class="" style="color: #67B52B">Confirmed</span>
                  <span name="confirmed" class="badge " style="background:#F0F8EA ">
                    <Check name="confirmed" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="potential"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="potential" class="" style="color: #FC6713">Potential</span>
                  <span name="potential" class="badge " style="background:rgba(252, 103, 19, 0.11) ">
                    <Question name="potential" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="irrelevant"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer ">
                  <span name="irrelevant" class="" style="color: #EB3131">Irrelevant</span>
                  <span name="irrelevant" class="badge " style="background:#FBEDED">
                    <Cross name="irrelevant" />
                  </span>
                </li>
                <li @mousedown="selectDropdown" name="unknown" class="p-2 flex items-center justify-between">
                  <span name="unknown" class="" style='color:#8D8D8D'>unknown</span>
                  <span name="unknown" class="badge" style="background:#D7D7D7">
                    <MinusIcon name="unknown" />
                  </span>
                </li>
              </ul>
            </div>
            <div class="mx-1 my-1">
              <Exclamation class="cursor-pointer" :fill="riskcolor" />
            </div>
            <div class="mx-1 my-1 ">
              <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="getIndividualData"
                :mainEntity="mainEntity" :sourceName="this.sourceName" @stateClear="updateSelectedPage" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import Exclamation from "@shared/assets/exclamation-red.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
import CanvasMenu from "../../components/canvas-menu";
import Check from '../../assets/svg/check.svg';
import Question from '../../assets/svg/question.svg';
import Cross from '../../assets/svg/cross-red.svg';
import { updateEntityDetails, updateInternetDataDetails } from "../../services";

export default {
  components: {
    Exclamation,
    MinusIcon,
    CanvasMenu,
    Check,
    Question,
    Cross
  },
  data() {
    return {
      showList: false,
      status: 'unknown',
      seeMore: false

    }
  },
  props: {
    mainEntity: Array,
    item: Object,
    name: String,
    sourceName: String,
    getOptions: Array,
    shrink: Boolean,
    title: {
      type: String,
      default: "Put Some Title",
    },
    props: {
      title: {
        type: String,
        default: "Put Some Title",
      },
      whithImage: Boolean,
      message: {
        type: String,
        default:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum consequuntur autem nihil impedit consequatur et eius reiciendis asperiores explicabo eos magni totam, quisquam, ut alias cupiditate iste veniam facilis ratione.",
      },
      item: {
        type: Object
      },

    },
  },
  computed: {
    getRiskCategories() {
      const categories = this.item.risk_categories || []
      const showCount = this.shrink ? 2 : 3
      const max = categories.length > showCount ? showCount : categories.length
      return this.seeMore ? categories : categories.slice(0, max)
    },

    isShrink() {
      return this.shrink
    },
    showSeeMore() {
      const showCount = this.shrink ? 2 : 3
      const count = this.item.risk_categories.length
      return count > showCount

    },
    getStatus() {
      return this.item?.status?.toLowerCase() || 'unknown'
    },
    confidenceColor() {
      return (this.item?.confidence_score?.name).toLowerCase();
    },
    getImage() {
      return this.name == 'internetsearch' || this.name == 'news' ? true : false;
    },
    getImageLink() {
      return this.name == 'internetsearch' ? this.item?.thumbnail : this.item.image;
    },
    getIndividualData() {
      return this.item;
    },
    getlink() {
      return this.name == 'court' || this.name == 'news' ? this.item.url : this.item.link;
    },
    getTitle() {
      // if (this.name == 'court' ) {
      //   return this.item.url.substr(0, 100)
      // }
      if (this.name == 'internetsearch') {
        return this.item.title.length > 50 ? this.item.title.substr(0, 40)+'...' : this.item.title
      }
      else if (this.name == 'news' || this.name == 'court') {
        // return this.item.entity.substr(0, 100)
        return this.item.entity.length > 50 ? this.item.entity.substr(0, 40)+'...' : this.item.entity
      }

      else {
        return this.item.link
      }
    },
    getdescription() {
      let courtString = ''
      if (this.name == 'court') {
        // this.item.snippet.forEach(i => courtString += `${i}, `)
        courtString = this.item.snippet;
      }
      return this.name == 'news' ? `${this.item.description.substr(0, 200)}...` : this.name == 'court' ? `${courtString.substr(0, 200)}...` : `${this.item?.snippet.substr(0, 200)}...`;
    },
    riskcolor() {
      if (this.item?.entity_status) {
        return this.item?.entity_status === 'Red' ? '#EB3131' : '#eb9b00'
      } else {
        return ''
      }
      // return this.item?.risk_categories.length > 0 ? '#EB3131' : '#eb9b00'
    },
  },
  methods: {
    updateSelectedPage(page){
       this.$emit('stateClear', page);
    },
    toggleSeeMore() {
      this.seeMore = !this.seeMore
    },
    formatText(text, maxlen = 40) {
      if (text) {
        let trimmed = text.length > maxlen ? text.slice(0, maxlen - 1) + "..." : text;
        return `${trimmed}`
      }
      return text
    },
    checkCategories(item) {
      let risk = this.getOptions?.find(v => v.category == item);
      if (risk) {
        return risk.category_type === 'Risk Categories' ? ((this.item.entity_status === 'Amber') ? 'amberLabel' : 'redLabel') : 'greenLabel';
      }
      else{
        return 'redLabel';
      }
    },
    closeDropdown() {
      this.showList = false;
    },
    toogleDropdown() {
      this.showList = !this.showList;
    },
    async selectDropdown(event) {
      this.showList = false;
      let key = event.target.innerText
      this.status = key.toLowerCase();
      this.item.status = this.status.toUpperCase();
      let payload = { ...this.item, case_id: this.$route.query.case_id }
      let data
      if (this.sourceName == 'internet') {
        data = await updateInternetDataDetails(payload, this.item._id);
        this.$emit("getGlobalFiltersData")
        this.$emit("pageUpdated")
      }
      else {
        data = await updateEntityDetails(payload, this.item._id);
        this.$emit("getGlobalFiltersData")
        this.$emit("pageUpdated")
      }
      if (data) {
        this.$toast.success("Status updated")
      }
    }
  },
  created() {
    if(this.individualData?.status != null){  
      this.status = (this.individualData?.status)?.toLowerCase() || 'unknown';
    } 
  },
};
</script>
<style lang="scss" scoped >
.label-box {
  display: flex;
  width: 25px;
  height: 24px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.label-box-bg {
  border-radius: 4px;
  background: var(--light-green, #E1F3D3);
}

.minus-box-bg {
  border-radius: 30px;
  background: #D7D7D7;
}

.label {
  color: var(--complete, #67B52B);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cst-buttons-height {
  max-height: 125px;
}

.unknown {
  border-radius: 30px;
  background: #D7D7D7;
}

.confirmed {
  border-radius: 30px;
  background: #F0F8EA;
}

.potential {
  border-radius: 30px;
  background: rgba(252, 103, 19, 0.11)
}

.irrelevant {
  border-radius: 30px;
  background: #FBEDED;
}

.high {
  font-size: 12px;
  font-weight: 400;
  color: #67B52B !important;
  background-color: #E1F3D3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  border: 0px solid transparent !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  z-index: 99999 !important;
  border-radius: 8px;
  background: var(--white, #FFF);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);
}

ul:hover {
  background-color: white !important;
}

li:hover {
  background-color: #D7D7D7;
}

.custom_card {
  display: flex;
  flex-direction: row;
}

.image-section {
  /* max-width: 25px; */
}

.header-link {
  word-break: break-all;
}

.linkHeader {
  // margin-top: -13px;
  margin-bottom: 5px;
  font-size: 11px;
  color: gray;
}

.data-section {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100% !important;
}

.description-section {
  word-break: break-all;
}

.amber {
  background-color: rgba(235, 155, 0, 0.2);
  color: #eb9b00;
}

.red {
  background: #FFEBEB;
  color: #EB3131
}


.redLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--light-red, #FFEBEB) !important;
  color: var(--red, #EB3131) !important;
}

.amberLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: rgba(235, 155, 0, 0.2) !important;
  color: rgb(235, 155, 0) !important;
}

.greenLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(5, 159, 39, 0.08) !important;
  color: var(--green, #42B85C) !important;
}

.f-title {
  color: var(--light-ray-main-red, #CE1B22);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

div.flex.flex-col.flex-grow-1{
  flex-grow: 1 !important;
}
</style>




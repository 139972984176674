<template>
    <div>
        <div class="grid grid-cols-2 gap-3">
            <div class="">
                <h2 class="mb-4">Research Notes</h2>
                <div :class="errorMessage && !value ? 'border border-solid border-l-0 border-red-600 ' : ''"
                    class="placeholder-[#282828]  scroll-bar border rounded-md flex " style="height:70vh !important">
                    <!-- <FormulateInput ref="file" type="file" v-model="files" class="w-full rounded-xl group cursor-pointer"
                        @input="handleFiles" multiple :add-label="true" :disabled="disabled" /> -->
                    <quill-editor class="scroll-bar border-3 flex flex-col"  style="width: 100%" 
                       v-model="handleEdit.notes" ref="myQuillEditor" :options="editorOptions"  />
                </div>
            </div>
            <div class="">
                <h2 class="mb-4">Customer Notes</h2>
                <div :class="errorMessage && !value ? 'border border-solid border-l-0 border-red-600 ' : ''"
                    class="placeholder-[#282828] border rounded-md flex w-full " style="height:70vh !important">
                    <quill-editor class="scroll-bar border-3 flex flex-col" style="width: 100%"
                        v-model="handleEdit.customer_notes" ref="myQuillEditor" :options="editorOptions" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { quillEditor } from "@shared/lib/vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
    name: "Notes",
    components: {
        quillEditor
    },
    props: {
        individualData: Object,
    },
    data() {
        return {
            researchNotes: "",
            customerNotes: "",
            editorOptions: {
                placeholder: "Insert text here",
                readOnly: true,
                theme: "snow",
                modules: {
                    toolbar: {
                        container: [
                            [{ 'font': [] }, { size: ["small", false, "large", "huge"] }, "bold", "italic", "underline", { 'color': [] }, { 'list': 'ordered' }, { 'align': [] }, 'clean'],                       // text direction
                        ],
                    },
                },
            },
        };
    },
    methods: {

    },
    mounted() {
        console.log(this.individualData)
    },
    computed: {
        handleEdit() {
            return this.individualData;
        }
    },
    watch: {
    
    }
};
</script>

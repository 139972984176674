import { KlChart } from "../vue-keylines";
// import osintAxios  from "../../axios/osint-axios"
import printDataMixin from "@shared/mixins/printDataMixin";
import { getOwnershiDetails } from "../../pages/search-records/services";

export default {
    name: "company-details",
    components:{
        KlChart,
    },
    data: function() {
        return {
            expandedDetails: {
                Details: false,
                officer: false,
                filings: false,
                ownership: false,
                financialDetails: false,
                graph: false,
                previousName:false
            },
            expandAll: true,
            company: {},
            matching: [],
        }
    },
    props: {
        companyDetails: Object,
        jurisdictionData: Array,
        ownership: Array,
        showPrintButton: Boolean,
        identifiers: Array,
    },
    mixins: [printDataMixin],
    computed:{
        handleGraph(){
            return this.company;
        },
        checkExpand(){
            if(this.expandedDetails.Details && this.expandedDetails.officer && this.expandedDetails.filings&& this.expandedDetails.ownership&&this.expandedDetails.financialDetails && this.expandedDetails.graph && this.expandedDetails.previousName){
              return false;
            }
            else {
              return true;
            }
          },
    },
    mounted(){
        this.handleCompanydetails();
    },
    methods: {
        highlightText(text, query) {
            const regex = new RegExp(`(${query})`, "gi");
            return text.replace(regex, (match) => {
              return `<span class='highlighted badge badge-warning'>${match}</span>`;
            });
        },
        async handleCompanydetails(){
            const response = this.companyDetails;
            // const { data: chain_data } = await osintAxios.post("/api/v1/company/search/chain", {
            //     comp_id: this.companyDetails.company.company_number,
            // })
            let payload={comp_id: this.companyDetails.company.company_number}
            const { data: chain_data } = await getOwnershiDetails(payload)
            this.ownership=chain_data.entity
            let {company } = this;
            company.modalCompanyDetailsData = response;
            if (company?.modalCompanyDetailsData?.company) {
                company.modalCompanyDetailsData.company.chain_data = chain_data;
                const nodes = company.modalCompanyDetailsData.company?.chain_data?.entity?.map((ele) => {
                    let node = {
                        type: "node",
                        id: ele._id,
                        t: ele.name,
                        // u: require(`@/assets/lightray/icons/flags/${ele.jurisdiction[0].code.split("_")[0].toLowerCase()}.svg`),
                    };
                    if (ele.main_node) {
                        node.ha0 = {
                            c: "#0d69d5", // the halo fill colour
                            r: 100, // the halo radius
                            w: 5, // the halo width
                        };
                    }
                    node.u = require(`../../../assets/lightray_new/icons/graph/${ele.name_type == "Company" ? "company_icon.png" : "user.png"}`);
                    if (ele?.jurisdiction?.length > 0 && ele?.jurisdiction[0]?.code) {
                        node.g = [
                            {
                                u: require(`../../../assets/lightray_new/icons/flags/${ele.jurisdiction[0].code.split("_")[0].toLowerCase()}.png`),
                                p: 305,
                                r: 40,
                            },
                        ];
                    }
                    return node;
                });
                const edges = company.modalCompanyDetailsData?.company?.chain_data?.relations.map((ele, i) => ({ type: "link", id: `${i + 1}_edge`, id1: ele.from, id2: ele.to, w: ele.active ? 2 : 1, a2: ele.from, c: ele.active ? "#000000" : "#AAAAAA" }));
                const graph_data = {
                    type: "LinkChart",
                    items: [...nodes, ...edges],
                };
                company.modalCompanyDetailsData.company.graph_data = graph_data;

            }
        },
        klReady(chart) {
            this.chart = chart;
            // this.initialiseInteractions();
            this.chart.options({ handMode: true });
            this.chart.layout("sequential", { tightness: 3, orientation: "down" });
        },
        graphCheck() {
            return true;
            // return companyDetails?.company?.chain_data?.entity.length > 0 && companyDetails?.company?.chain_data?.entity.length > 0;
        },
        getJurisdictionLabel(code) {
            let data = this.jurisdictionData.filter(res=> res.value == code)
            return data[0].label
        },
        handleExpandAll() {
            this.expandedDetails.Details = true;
            this.expandedDetails.ownership = true;
            this.expandedDetails.financialDetails = true;
            this.expandedDetails.officer = true;
            this.expandedDetails.filings = true;
            this.expandedDetails.graph = true;
            this.expandedDetails.previousName=true;
            this.expandAll = false;
        },
        handleCollapseAll() {
            this.expandedDetails.Details = false;
            this.expandedDetails.ownership = false;
            this.expandedDetails.financialDetails = false;
            this.expandedDetails.officer = false;
            this.expandedDetails.filings = false;
            this.expandedDetails.graph = false;
            this.expandedDetails.previousName=false;
            this.expandAll = true  
        },
        async printDocuments(id, fileName, ) {
            this.handleExpandAll();
            this.isPrintLoader = true;
            let htmlPDF = "";
            setTimeout(async()=>{
                htmlPDF = await this.printPage(id, fileName, )
                this.handleCollapseAll();
                return htmlPDF;
            },450)
          },
          async printPage(id, name, print, ) {
            this.printDone = false;
            this.isPrintLoader = true;
            if (!print) {
              let htmlPDF = await this.printData(id, name, );
              this.$emit("updatePrintStatus", "");
              this.isPrintLoader = false;
                return htmlPDF;
            }
          },
        
    },
    updated(){
       const news = this.$refs.news;
       if (news) {
          const elements = news.querySelectorAll("[data-attribute]");
          console.log('elements', elements)
          if(elements.length){
          elements.forEach((element) => {
            const innerHtml = element.innerHTML;
            let attributeValue = element.getAttribute("data-attribute");
            if(attributeValue.length)
            {
              attributeValue =  attributeValue.split(",");
              let highlightedHtml = innerHtml;
              attributeValue.forEach((att) => {
              highlightedHtml = this.highlightText(highlightedHtml, att);
              });
             element.innerHTML = highlightedHtml;
            }
          });
        }
      }
   },
   created(){
    this.matching = this.identifiers.map(i => i.entity);
    // console.log("=-=-=-=-=-=-",this.matching)
   }

}